<div class="info" *ngIf="appearance === 'INFO'">
    <mat-icon>info</mat-icon> {{message}}
    <button *ngIf="closable" mat-icon-button class="close-button" (click)="close()">
        <mat-icon>cancel</mat-icon>
    </button>
</div>
<div class="success" *ngIf="appearance === 'SUCCESS'">
    <mat-icon>check_circle</mat-icon> {{message}}
    <button *ngIf="closable" mat-icon-button class="close-button" (click)="close()">
        <mat-icon>cancel</mat-icon>
    </button>
</div>
<div class="warning" *ngIf="appearance === 'WARNING'">
    <mat-icon>warning</mat-icon> {{message}}
    <button *ngIf="closable" mat-icon-button class="close-button" (click)="close()">
        <mat-icon>cancel</mat-icon>
    </button>
</div>
<div class="error" *ngIf="appearance === 'ERROR'">
    <mat-icon>error</mat-icon> {{message}}
    <button *ngIf="closable" mat-icon-button class="close-button" (click)="close()">
        <mat-icon>cancel</mat-icon>
    </button>
</div>
