import {NgModule} from "@angular/core";
import {SearchComponent} from "./search-component/search.component";
import {AppRoutingModule} from "../app-routing.module";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {searchReducer, searchStoreFeatureKey} from "./store/search.reducer";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatTabsModule} from "@angular/material/tabs";
import {CdkScrollable} from "@angular/cdk/overlay";
import {MatMenuModule} from "@angular/material/menu";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {BASE_PATH as SEARCH_BASE_PATH} from "../search/generated-sources/api/";

@NgModule({
    declarations: [
        SearchComponent,
    ],
    exports: [
        SearchComponent
    ],
    imports: [
        AppRoutingModule,
        CommonModule,
        StoreModule.forFeature(searchStoreFeatureKey, searchReducer),
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatListModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        CdkScrollable,
        MatMenuModule,
        MatOptionModule,
        MatSelectModule
    ],
    providers: [
        {provide: SEARCH_BASE_PATH, useValue: ".."}
    ]
})
export class SearchModule {
}
