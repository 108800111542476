import {DateUtil} from "../utils/date-util";

export interface Traceable {
    source?: string;
    modDate?: string;
    refDocument?: string;
    userId?: number;
}

export interface TraceableText extends Traceable {
    text?: string;
}

export interface TraceableCode extends Traceable {
    code?: string;
}

export interface TraceableMoney extends Traceable {
    amount?: number;
}

export interface TraceableMoneyWithCurrency extends TraceableMoney {
    currencyIso?: string;
}

export interface TraceablePercent extends Traceable {
    fraction?: number;
}

export interface TraceableDate extends Traceable {
    date?: string;
}

export interface TraceableMultiple extends Traceable {
    factor?: number;
}

export interface TraceablePeriod extends Traceable {
    keyDate?: string;
    type?: "QUARTER" | "SEMESTER" | "FULL_FISCAL_YEAR";
}

export interface TraceableBoolean extends Traceable {
    value?: boolean;
}

export const EMPTY_TRACEABLE: Traceable = {source: "initial", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined};
export const EMPTY_TEXT: TraceableText = {source: "initial", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, text: ""};
export const EMPTY_CODE: TraceableCode = {source: "initial", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, code: undefined};
export const EMPTY_MONEY: TraceableMoney = {source: "initial", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, amount: undefined};
export const EMPTY_PERCENT: TraceablePercent = {source: "initial", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, fraction: undefined};
export const EMPTY_MODEL_DATE: TraceableDate = {source: "initial", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, date: ""};
export const EMPTY_MULTIPLE: TraceableMultiple = {source: "initial", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, factor: undefined};
export const EMPTY_MONEY_WITH_CURRENCY: TraceableMoneyWithCurrency = {
    source: "",
    modDate: DateUtil.isoTimestamp(),
    refDocument: "",
    userId: undefined,
    currencyIso: "CHF",
    amount: undefined
};
export const EMPTY_PERIOD: TraceablePeriod = {
    source: "",
    modDate: DateUtil.isoTimestamp(),
    refDocument: "",
    userId: undefined,
    keyDate: "",
    type: "FULL_FISCAL_YEAR"
};
export const EMPTY_BOOLEAN: TraceableBoolean = {
    source: "initial",
    modDate: DateUtil.isoTimestamp(),
    refDocument: "",
    userId: undefined,
    value: undefined
};

export const isText = (object: any): object is TraceableText => object.hasOwnProperty("text");
export const isCode = (object: any): object is TraceableCode => object.hasOwnProperty("code");
export const isMoney = (object: any): object is TraceableMoney => object.hasOwnProperty("amount");
export const isPercent = (object: any): object is TraceablePercent => object.hasOwnProperty("fraction");
export const isModelDate = (object: any): object is TraceableDate => object.hasOwnProperty("date");
export const isMultiple = (object: any): object is TraceableMultiple => object.hasOwnProperty("factor");
export const isMoneyWithCurrency = (object: any): object is TraceableMoneyWithCurrency => object.hasOwnProperty("currencyIso");
export const isPeriod = (object: any): object is TraceablePeriod => object.hasOwnProperty("type") && object.hasOwnProperty("keyDate");
export const isBoolean = (object: any): object is TraceableBoolean => object.hasOwnProperty("value");

