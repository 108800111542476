import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {Note} from "src/app/shared/model/note";
import {AssetValuation} from "../../models/asset-valuation/asset-valuation";
import {Asset} from "../../models/asset/asset";
import {AssetValuationScenario} from "../../models/asset-valuation/asset-valuation-scenario";
import {FundInvestment} from "../../../fund/models/fund-investement";
import {AssetFinancialHistoryRecord} from "../../models/asset/asset-financial-history-record";
import {TraceableCode} from "../../../shared/model/traceable";
import {Shareholder} from "../../models/asset/shareholder";
import {TraceableText} from "../../generated-sources/api";
import {Swot} from "../../models/asset/swot";

export const AssetListActions = createActionGroup({
    source: "Asset List Action",
    events: {
        open: emptyProps(),
        load: emptyProps(),
        loaded: props<{ assets: Asset[] }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export const AssetActions = createActionGroup({
    source: "Asset Action",
    events: {
        open: props<{ assetId: number; fundId: number | undefined }>(),
        create: props<{ assetName: string }>(),
        load: props<{ assetId: number }>(),
        loaded: props<{ asset: Asset }>(),
        loadBaseline: props<{ assetId: number }>(),
        loadedBaseline: props<{ asset: Asset }>(),
        savedLoaded: props<{ asset: Asset }>(),
        loadError: props<{ errorMsg: string }>(),
        clearAssetState: emptyProps(),
        setMergeTargetId: props<{ mergeTargetId?: number }>()
    }
});

export const AssetValuationActions = createActionGroup({
    source: "Asset Valuation Actions",
    events: {
        create: props<{ assetId: number; reportDate: string }>(),
        load: props<{ assetId: number; assetValuationId: number }>(),
        loaded: props<{ assetId: number; assetValuation: AssetValuation }>(),
        savedloaded: props<{ assetId: number; assetValuation: AssetValuation }>(),
        loadAll: props<{ assetId: number }>(),
        loadedAll: props<{ assetId: number; valuations: AssetValuation[] }>(),
        loaderror: props<{ errorMsg: string }>(),
        addToFundValuation: props<{ assetId: number; assetValuationId: number }>(),
        delete: props<{ assetId: number; assetValuationId: number }>(),
        deleted: emptyProps()
    }
});

export const AssetValuationScenarioActions = createActionGroup({
    source: "Asset Valuation Scenario Actions",
    events: {
        loadedall: props<{ scenarios: AssetValuationScenario[] }>(),
        loaderror: props<{ errorMsg: string }>()
    }
});

export const GeneralPartnerValuationActions = createActionGroup({
    source: "Asset General Partner Valuation Actions",
    events: {
        load: props<{ fundInvestmentId: number }>(),
        loaded: props<{ fundInvestment: FundInvestment }>(),
        edit: emptyProps(),
        save: props<{ fundInvestment: FundInvestment }>(),
        savedLoaded: props<{ fundInvestment: FundInvestment }>(),
        calc: props<{
            remainingCost: number | undefined;
            mezzanine: number | undefined;
            preferredEquity: number | undefined;
            commonEquity: number | undefined;
            realizedCost: number | undefined;
            realizedGains: number | undefined;
        }>(),
        calcSuccess: props<{ fundInvestment: FundInvestment }>(),
        cancel: emptyProps(),
        loaderror: props<{ errorMsg: string }>()
    }
});

export const AssetNoteActions = createActionGroup({
    source: "Asset Note Actions",
    events: {
        load: props<{ inContext: string }>(),
        loaded: props<{ notes: Note[] }>(),
        loaderror: props<{ errorMsg: string }>(),
        save: props<{ context: string; note: Note }>(),
        delete: props<{ note: Note }>()
    }
});

export const AssetSwotActions = createActionGroup({
    source: "Asset Swot Action",
    events: {
        edit: emptyProps(),
        save: props<{
            swotStrengths: string;
            swotWeaknesses: string;
            swotOpportunities: string;
            swotThreats: string;
        }>(),
        cancel: emptyProps(),
        gptEdit: emptyProps(),
        gptCancel: emptyProps(),
        generateSuccess: props<{ swot: Swot }>(),
        generateFailure: props<{ errorMsg: string }>(),
    }
});

export const AssetNameActions = createActionGroup({
    source: "Asset Name Action",
    events: {
        edit: emptyProps(),
        save: props<{ assetName: string }>(),
        cancel: emptyProps()
    }
});

export const AssetDescriptionActions = createActionGroup({
    source: "Asset Description Action",
    events: {
        edit: emptyProps(),
        save: props<{
            assetDescription: string;
            assetUrl: string;
        }>(),
        cancel: emptyProps(),
        gptEdit: emptyProps(),
        gptCancel: emptyProps(),
        generateSuccess: props<{ description: TraceableText }>(),
        generateFailure: props<{ errorMsg: string }>(),
    }
});

export const EndMarketsCustomersActions = createActionGroup({
    source: "End Markets / Customers Action",
    events: {
        edit: emptyProps(),
        save: props<{
            text: string;
        }>(),
        cancel: emptyProps(),
        gptEdit: emptyProps(),
        gptCancel: emptyProps(),
        generateSuccess: props<{ description: TraceableText }>(),
        generateFailure: props<{ errorMsg: string }>(),
    }
});

export const ExitsActions = createActionGroup({
    source: "Exits Action",
    events: {
        edit: emptyProps(),
        save: props<{
            entryDate: string | null;
            effectiveExitDate: string | null;
            exitDateAssumption: string | null;
        }>(),
        cancel: emptyProps()
    }
});

export const AssetDiscountValuationActions = createActionGroup({
    source: "Asset Discount Multiple Valuation Action",
    events: {
        edit: emptyProps(),
        save: props<{
            assetValuation: AssetValuation;
            scenarios: AssetValuationScenario[];
        }>(),
        calc: props<{
            assetValuation: AssetValuation;
            scenarios: AssetValuationScenario[];
            save: boolean;
        }>(),
        calcSuccess: props<{
            assetValuation: AssetValuation;
            scenarios: AssetValuationScenario[];
        }>(),
        calcError: props<{ errorMsg: string }>(),
        cancel: emptyProps(),
        editRationale: emptyProps(),
        saveRationale: props<{
            assetValuation: AssetValuation;
        }>(),
        cancelRational: emptyProps()
    }
});

export const AssetGeneralInformationActions = createActionGroup({
    source: "Asset General Information Action",
    events: {
        edit: emptyProps(),
        save: props<{
            country: string;
            activityDescription: string | null;
            regions: TraceableCode[];
            stage: string;
            gic: string | null;
            gpIndication: string;
            currency: string;
            esg: string;
            fiscalYearEnd: string;
            assessment: string;
            publiclyListed: boolean;
        }>(),
        cancel: emptyProps()
    }
});

export const AssetFinancialHistoryActions = createActionGroup({
    source: "Asset Financial History Action",
    events: {
        load: props<{ assetId: number }>(),
        loadSuccess: props<{ financialHistory: AssetFinancialHistoryRecord[] }>(),
        loaderror: props<{ errorMsg: string }>(),
        loadBaseline: props<{ assetId: number }>(),
        loadedBaseline: props<{ financialHistory: AssetFinancialHistoryRecord[] }>(),
        edit: emptyProps(),
        save: props<{ financialHistory: AssetFinancialHistoryRecord[] }>(),
        savedLoaded: props<{ financialHistory: AssetFinancialHistoryRecord[] }>(),
        saveError: props<{ errorMsg: string }>(),
        cancel: emptyProps(),
        calc: props<{ financialHistoryRecord: AssetFinancialHistoryRecord[] }>(),
        calcSuccess: props<{ financialHistory: AssetFinancialHistoryRecord[] }>(),
        calcError: props<{ errorMsg: string }>(),
    }
});

export const ShareholderActions = createActionGroup({
    source: "Asset Shareholder Structure Action",
    events: {
        load: props<{ assetId: number; date: string }>(),
        loadSuccess: props<{ shareholders: Shareholder[] }>(),
        loadError: props<{ errorMsg: string }>(),
        loadAll: props<{ assetId: number }>(),
        loadAllSuccess: props<{ shareholders: Shareholder[] }>(),
        edit: emptyProps(),
        save: props<{ assetId: number; date: string; shareholders: Shareholder[] }>(),
        savedLoaded: props<{ shareholders: Shareholder[] }>(),
        saveError: props<{ errorMsg: string }>(),
        cancel: emptyProps()
    }
});
