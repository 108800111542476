import {Component, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {AccelexImportActions} from "../store/import.actions";
import {selectFileNames, selectImportLogs} from "../store/import.selectors";
import {selectDeals} from "../../deal/store/deal/deal.selectors";
import {Deal} from "../../deal/models/deal";
import {ConfirmationComponent} from "../../shared/components/confirmation/confirmation.component";
import {catchError, forkJoin, map, Observable, of} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ImportLog} from "../models/import-log";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: "valumize-import-dataset-dialog",
    templateUrl: "./import-dataset-dialog.component.html",
    styleUrls: ["./import-dataset-dialog.component.scss"]
})
export class ImportDatasetDialogComponent implements OnInit {

    selectableFilesColumns = ["fileName", "add"];
    selectedFilesColumns = ["fileName", "remove"];
    selectableDealsColumns = ["id", "name", "closingDate", "add"];

    fileNames$ = this.store.select(selectFileNames);
    deals$ = this.store.select(selectDeals);

    selectedFiles: string[] = [];
    selectedDeal?: Deal;
    importLogs: ImportLog[] = [];
    dealsTablePanelOpenState = false;

    constructor(
        private readonly store: Store,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar
    ) {
        this.store.dispatch(AccelexImportActions.load());
    }

    ngOnInit() {
        this.store.select(selectImportLogs).pipe(map(importLogs => {
            this.importLogs = importLogs;
        })).subscribe();
    }

    addFileToSelection(fileToAdd: string) {
        this.selectedFiles = [...this.selectedFiles, fileToAdd];
    }

    removeFileFromSelection(fileToRemove: string) {
        this.selectedFiles = this.selectedFiles.filter(file => file !== fileToRemove);
    }

    filterNotSelected(files: string[]): string[] {
        return files.filter(file => !this.selectedFiles.includes(file));
    }

    importFiles() {
        if (this.selectedFiles.length === 0) {
            return;
        }

        const filesToImport: string[] = [];
        const dialogRefSubscriptions: Observable<any>[] = [];
        this.selectedFiles.forEach(file => {
            if (this.importLogs.map(importLog => importLog.fileName).includes(file)) {
                const dialogRef = this.dialog.open(ConfirmationComponent);
                dialogRef.componentInstance.confirmMessage = `You have already imported the file ${file}. Are you sure you want to import it again?`;
                dialogRefSubscriptions.push(dialogRef.afterClosed().pipe(
                    map(result => !!result ? filesToImport.push(file) : null),
                    catchError(() => of(null))
                ));
            } else {
                filesToImport.push(file);
            }
        });

        const processImports = () => {
            if (filesToImport.length > 0) {
                this.store.dispatch(AccelexImportActions.import({filesNames: filesToImport, dealId: this.selectedDeal?.id}));
                this.snackBar.open("The dataset is being processed, it may take a few seconds to appear.", "", {
                    duration: 4000
                });
            }
        };

        if (dialogRefSubscriptions.length > 0) {
            forkJoin(dialogRefSubscriptions).pipe(
                map(() => processImports())
            ).subscribe();
        } else {
            processImports();
        }
    }

    selectDeal(deal: Deal) {
        this.selectedDeal = deal;
    }

    removeSelectedDeal() {
        this.selectedDeal = undefined;
    }
}
