/* eslint-disable arrow-body-style */
import {inject} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {AssetValuationService} from "../../services/asset-valuation.service";
import {AssetDiscountValuationActions, AssetValuationActions, AssetValuationScenarioActions} from "../asset/asset.actions";
import {Store} from "@ngrx/store";
import {selectSelectedAssetId} from "../asset/asset.selectors";
import {selectSelectedValuationId} from "./asset-valuation.selectors";
import {DiscountMultipleValuation} from "../../models/asset-valuation/discount-multiple-valuation";
import {selectSelectedFundValuation, selectSelectedFundValuationClosingDate} from "../../../fund/store/fund-valuation/fund-valuation.selectors";
import {selectSelectedFundInvestment} from "../../../fund/store/fund-investment/fund-investment.selectors";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {selectSelectedFundId} from "../../../fund/store/fund/fund.selectors";
import {FundAssetValuation, FundValuation} from "../../../fund/generated-sources/api";
import {FundValuationActions} from "../../../fund/store/fund.actions";
import {EMPTY_ASSET_VALUATION} from "../asset.reducer";
import {FundValuationService} from "../../../fund/services/fund-valuation.service";

export const createAssetValuation = createEffect(
    (actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.create),
            exhaustMap((action) => {
                if (action.assetId) {
                    return assetValuationService.save(
                        action.assetId,
                        {
                            ...EMPTY_ASSET_VALUATION,
                            ...{
                                reportDate: {
                                    ...EMPTY_ASSET_VALUATION.reportDate,
                                    date: action.reportDate
                                }
                            }
                        }
                    ).pipe(
                        switchMap((assetValuation) => of(
                            AssetValuationActions.loaded({assetId: action.assetId, assetValuation}),
                            assetValuation.id
                                ? AssetValuationActions.addtofundvaluation({assetId: action.assetId, assetValuationId: assetValuation.id})
                                : AssetValuationActions.loaderror({errorMsg: "Asset valuation was not added to the according fund valuation"})
                        )),
                        catchError((error: { message: string }) =>
                            of(AssetValuationActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetValuationActions.loaderror({errorMsg: "Asset id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const addAssetValuationToFundValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.addtofundvaluation),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundValuation)),
            exhaustMap(([action, fundId, fundValuation]) => {
                const newFundAssetValuation: FundAssetValuation = {
                    id: undefined,
                    assetValuationId: action.assetValuationId,
                };
                const updatedFundValuation: FundValuation = {
                    ...fundValuation.data,
                    fundAssetValuations: [...fundValuation.data.fundAssetValuations, newFundAssetValuation]
                };
                if (fundId) {
                    return fundValuationService.save(fundId, updatedFundValuation).pipe(
                        map((fundValuationResponse) => FundValuationActions.loaded({fundValuation: fundValuationResponse})),
                        tap(() => window.location.reload()),
                        catchError((error: { message: string }) =>
                            of(FundValuationActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundValuationActions.loaderror({errorMsg: "Fund id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadAssetValuations = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.loadall),
            exhaustMap((action) =>
                assetValuationService.getValuationsForAsset(action.assetId).pipe(
                    map((valuations) => AssetValuationActions.loadedall({assetId: action.assetId, valuations})),
                    catchError((error: { message: string }) =>
                        of(AssetValuationActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadAssetValuation = createEffect(
    (actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.load),
            exhaustMap((action) =>
                assetValuationService.getAssetValuation(action.assetId, action.assetValuationId).pipe(
                    map((assetValuation) => AssetValuationActions.loaded({assetId: action.assetId, assetValuation})),
                    catchError((error: { message: string }) =>
                        of(AssetValuationActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadAssetValuationScenarios = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.loaded),
            exhaustMap((action) => {
                if (!!action.assetId && !!action.assetValuation.id) {
                    return assetValuationService.getScenariosForAssetValuation(action.assetId, action.assetValuation.id).pipe(
                        map((scenarios) => AssetValuationScenarioActions.loadedall({scenarios})),
                        catchError((error: { message: string }) =>
                            of(AssetValuationScenarioActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetValuationScenarioActions.loaderror({errorMsg: "Asset valuation id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveAssetValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetDiscountValuationActions.save, AssetDiscountValuationActions.saverationale),
            withLatestFrom(store$.select(selectSelectedAssetId)),
            exhaustMap(([action, assetId]) => {
                if (assetId === undefined) {
                    return of();
                }
                return assetValuationService.save(assetId, action.assetValuation).pipe(
                    map((assetValuation) => AssetValuationActions.savedloaded({assetId, assetValuation})),
                    catchError((error: { message: string }) =>
                        of(AssetValuationActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const deleteAssetValuation = createEffect(
    (actions$ = inject(Actions), router = inject(Router), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.delete),
            exhaustMap((action) =>
                assetValuationService.delete(action.assetId, action.assetValuationId).pipe(
                    map(() => AssetValuationActions.deleted()),
                    tap((loadedAction) => {
                        if (loadedAction.type === AssetValuationActions.deleted.type) {
                            router.navigate([], {queryParams: {assetValuationId: null}, queryParamsHandling: "merge"});
                        }
                    }),
                    catchError((error: { message: string }) =>
                        of(AssetValuationActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const saveAssetValuationScenarios = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetDiscountValuationActions.save),
            withLatestFrom(store$.select(selectSelectedAssetId), store$.select(selectSelectedValuationId)),
            exhaustMap(([action, assetId, assetValuationId]) => {
                if (assetId === undefined || assetValuationId === undefined) {
                    return of();
                }
                return assetValuationService.saveScenariosForAssetValuation(assetId, assetValuationId, action.scenarios).pipe(
                    map((scenarios) => AssetValuationScenarioActions.loadedall({scenarios})),
                    catchError((error: { message: string }) =>
                        of(AssetValuationScenarioActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const calcDiscountMultipleValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetDiscountValuationActions.calc),
            withLatestFrom(
                store$.select(selectSelectedAssetId),
                store$.select(selectSelectedValuationId),
                store$.select(selectSelectedFundValuationClosingDate),
                store$.select(selectSelectedFundInvestment)
            ),
            exhaustMap(([action, assetId, assetValuationId, closingDate, fundInvestment]) => {
                if (assetId === undefined || assetValuationId === undefined) {
                    return of();
                }
                const discountMultipleValuation: DiscountMultipleValuation = {
                    assetValuation: action.assetValuation,
                    scenarios: action.scenarios,
                    closingDate,
                    fundInvestmentData: {
                        nav: fundInvestment.data.nav,
                        totalCost: fundInvestment.data.totalCost,
                        totalRealized: fundInvestment.data.totalRealized
                    }
                };
                return assetValuationService.calcDiscountMultipleValuation(assetId, assetValuationId, discountMultipleValuation).pipe(
                    map((dmv) => (action.save) ?
                        AssetDiscountValuationActions.save({assetValuation: dmv.assetValuation, scenarios: dmv.scenarios}) :
                        AssetDiscountValuationActions.calcsuccess({assetValuation: dmv.assetValuation, scenarios: dmv.scenarios})),
                    catchError((error: { message: string }) =>
                        of(AssetDiscountValuationActions.calcerror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);
