/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {FundInvestmentActions, FundReportActions, FundValuationActions} from "../fund.actions";
import {catchError, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {EMPTY_FUND_REPORT} from "../fund.reducer";
import {FundReportService} from "../../services/fund-report.service";
import {tap} from "rxjs/operators";
import {selectSelectedFundReport} from "./fund-report.selectors";
import {FundReport} from "../../models/fund-report";
import {selectSelectedFundId} from "../fund/fund.selectors";

export const loadFundReport = createEffect(
    (actions$ = inject(Actions), fundReportService = inject(FundReportService)) => {
        return actions$.pipe(
            ofType(FundReportActions.load),
            exhaustMap((action) => {
                return fundReportService.getFundReport(action.fundId, action.fundReportId).pipe(
                    switchMap((fundReport) =>
                        !!action.fundValuationId
                            ? of(
                                FundReportActions.loaded({fundReport}),
                                FundValuationActions.load({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId}),
                                FundInvestmentActions.loadall({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId}))
                            : of(
                                FundReportActions.loaded({fundReport}),
                                FundInvestmentActions.loadall({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId}))
                    ),
                    catchError((error: { message: string }) =>
                        of(FundReportActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadBaselineFundReport = createEffect(
    (actions$ = inject(Actions), fundReportService = inject(FundReportService)) => {
        return actions$.pipe(
            ofType(FundReportActions.loadbaseline),
            exhaustMap((action) =>
                fundReportService.getFundReport(action.fundId, action.fundReportId).pipe(
                    map((fundReport) => FundReportActions.loadedbaseline({fundReport})),
                    catchError((error: { message: string }) =>
                        of(FundReportActions.loaderror({errorMsg: "Baseline Fund Report" + error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadFundReports = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundReportService = inject(FundReportService)) => {
        return actions$.pipe(
            ofType(FundReportActions.loadall),
            exhaustMap((action) =>
                fundReportService.getReportsForFund(action.fundId).pipe(
                    map((reports) => action.fundIsMergeTarget
                        ? FundReportActions.loadedallbaseline({reports})
                        : FundReportActions.loadedall({fundId: action.fundId, reports})),
                    catchError((error: { message: string }) =>
                        of(FundReportActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const createFundReport = createEffect(
    (actions$ = inject(Actions), fundReportService = inject(FundReportService)) => {
        return actions$.pipe(
            ofType(FundReportActions.create),
            exhaustMap((action) => {
                if (!!action.fundId) {
                    return fundReportService.save(
                        action.fundId,
                        {
                            ...EMPTY_FUND_REPORT,
                            ...{
                                reportDate: {
                                    ...EMPTY_FUND_REPORT.reportDate,
                                    date: action.reportDate
                                }
                            }
                        }
                    ).pipe(
                        map((fundReport) => FundReportActions.loaded({fundReport})),
                        tap(() => window.location.reload()),
                        catchError((error: { message: string }) =>
                            of(FundReportActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundReportActions.loaderror({errorMsg: "Fund id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveFundReport = createEffect(
    (actions$ = inject(Actions), fundReportService = inject(FundReportService)) => {
        return actions$.pipe(
            ofType(FundReportActions.save),
            exhaustMap((action) => {
                if (!!action.fundId && !!action.fundReport.id) {
                    return fundReportService.save(
                        action.fundId,
                        {
                            ...action.fundReport
                        }
                    ).pipe(
                        map((fundReport) => FundReportActions.loaded({fundReport})),
                        catchError((error: { message: string }) =>
                            of(FundReportActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundReportActions.loaderror({errorMsg: "Fund id and Fund Report Id are required"}));
                }
            })
        );
    },
    {functional: true}
);

export const calcTotalUndrawn = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundReportService = inject(FundReportService)) => {
        return actions$.pipe(
            ofType(FundReportActions.calc),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundReport),
            ),
            exhaustMap(([action, fundId, fundReport]) => {
                if (fundId && fundReport.fundReport.data.id) {
                    return fundReportService.calcTotalUndrawn(fundId, fundReport.fundReport.data.id, {
                            ...fundReport.fundReport.data,
                            reportedUndrawn: {
                                ...fundReport.fundReport.data.reportedUndrawn,
                                amount: action.reportedUndrawn
                            },
                            recallableDistributions: {
                                ...fundReport.fundReport.data.recallableDistributions,
                                amount: action.recallableDistributions
                            },
                    }).pipe(
                        map((fundReportResponse) => FundReportActions.calcsuccess({fundReport: fundReportResponse})),
                        catchError((error: { message: string }) =>
                            of(FundReportActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundReportActions.loaderror({errorMsg: "Fund id and Fund Report Id are required"}));
                }
            })
        );
    },
    {functional: true}
);

export const setMergeTargetId = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundReportService = inject(FundReportService)) => {
        return actions$.pipe(
            ofType(FundReportActions.setmergetargetid),
            withLatestFrom(store$.select(selectSelectedFundReport)),
            exhaustMap(([action, selectedFundReportData]) => {
                const fundId = action.fundId;
                const selectedFundReport: FundReport = selectedFundReportData.fundReport.data;
                if (fundId) {
                    return fundReportService.save(fundId, {
                        ...selectedFundReport,
                        ...{
                            mergeTargetId: action.mergeTargetId
                        }
                    }).pipe(
                        switchMap((fundReport) =>
                            fundReport.mergeTargetId
                                ? of(FundReportActions.loaded({fundReport}), FundReportActions.loadbaseline({fundId, fundReportId: fundReport.mergeTargetId}))
                                : of(FundReportActions.clearbaseline(), FundReportActions.loaded({fundReport}))
                        ),
                        catchError((error: { message: string }) =>
                            of(FundReportActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundReportActions.loaderror({errorMsg: "Fund id is required"}));
                }
            })
        );
    },
    {functional: true}
);
