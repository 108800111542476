import {Injectable} from "@angular/core";
import {FundApiService} from "../generated-sources/api";
import {map, Observable} from "rxjs";
import {FundReport} from "../models/fund-report";

@Injectable({
    providedIn: "root"
})
export class FundReportService {

    constructor(private readonly fundApiService: FundApiService) {
    }

    public getReportsForFund(fundId: number): Observable<FundReport[]> {
        return this.fundApiService.getReportsForFund(fundId);
    }

    public getFundReport(fundId: number, fundReportId: number): Observable<FundReport> {
        return this.fundApiService.getFundReport(fundId, fundReportId);
    }

    public save(fundId: number, report: FundReport): Observable<FundReport> {
        const result: Observable<FundReport> = !!report.id && fundId
            ? this.fundApiService.updateFundReport(fundId, report.id, report)
            : this.fundApiService.createFundReport(fundId, report);
        return result.pipe(map((fundReportDto: FundReport) => fundReportDto));

    }

    public calcTotalUndrawn(fundId: number, fundReportId: number, report: FundReport): Observable<FundReport> {
        return this.fundApiService.calculateTotalUndrawn(fundId, fundReportId, report);
    }
}
