<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Seller Position</mat-card-title>
    </mat-card-header>
    <mat-card-subtitle>
        <ng-container *ngIf="(fundSellerPosition$ | async) as fundSellerPosition">
            <valumize-info-panel *ngIf="fundSellerPosition.sellerPosition.status === 'ERROR'" [appearance]="'ERROR'"
                                 [message]="fundSellerPosition.sellerPosition.errorMessage"></valumize-info-panel>
            <valumize-info-panel *ngIf="fundSellerPosition.sellerPosition.status === 'SAVED'" [appearance]="'SAVED'"
                                 [message]="'Seller Position successfully saved'"></valumize-info-panel>
            <mat-progress-spinner *ngIf="fundSellerPosition.sellerPosition.status === 'LOADING'"></mat-progress-spinner>
        </ng-container>
    </mat-card-subtitle>
    <ng-container *ngIf="dealId$ | async as dealId; else selectDealFirst">
        <ng-container *ngIf="fundSellerPosition$ | async as fundSellerPosition">
            <mat-card-content class="detail-card-content">
                <form [formGroup]="sellerPositionForm">
                    <div class="key-value-pair-section">
                        <div class="key-value-pair-column">
                            <div>
                                <h3 data-test="subtitle-commitment">Commitment</h3>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Original Commitment</div>
                                    <div class="key-value-item">
                                        <ng-container *ngIf="!isEditable; else editOriginalCommitment">
                                            {{fundSellerPosition.sellerPosition.data.sellerCommitment | traceableFormat}}
                                        </ng-container>
                                        <ng-template #editOriginalCommitment>
                                            <mat-form-field>
                                                <input matInput type="number" formControlName="originalCommitment" id="originalCommitment">
                                            </mat-form-field>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Expired Released Commitments</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPosition.data.expiredReleasedCommitments | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Drawn Commitments</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPosition.data.drawnCommitments | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Distributions</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPosition.data.distributions | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Account Balance Before Carry</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPosition.data.accountBalanceBeforeCarry | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Realized Carried Interest</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPosition.data.realizedCarriedInterest | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Total Carried Interest</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPosition.data.totalCarriedInterest | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">GP Valuation</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPosition.data.gpValuation | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Adjusted Commitment</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPositionCalc.adjustedCommitment | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Final Adjusted Commitment (Net Of CFs)</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPositionCalc.finalAdjustedCommitment | traceableFormat}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="key-value-pair-column">
                            <div>
                                <h3 data-test="subtitle-undrawn">Undrawn</h3>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Recallable Distribution</div>
                                    <div class="key-value-item">
                                        <ng-container *ngIf="!isEditable; else editRecallableDistribution">
                                            {{fundSellerPosition.sellerPosition.data.recallableDistribution | traceableFormat}}
                                        </ng-container>
                                        <ng-template #editRecallableDistribution>
                                            <mat-form-field>
                                                <input matInput type="number" formControlName="recallableDistribution" id="recallableDistribution">
                                            </mat-form-field>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Total Undrawn</div>
                                    <div class="key-value-item">
                                        <ng-container *ngIf="!isEditable; else editUnfundedCommitments">
                                            {{fundSellerPosition.sellerPosition.data.unfundedCommitments | traceableFormat}}
                                            ({{fundSellerPosition.sellerPositionCalc.totalUndrawn | traceableFormat}})
                                        </ng-container>
                                        <ng-template #editUnfundedCommitments>
                                            <mat-form-field>
                                                <input matInput type="number" formControlName="unfundedCommitments" id="unfundedCommitments">
                                            </mat-form-field>
                                            ({{fundSellerPosition.sellerPositionCalc.totalUndrawn | traceableFormat}})
                                        </ng-template>
                                    </div>
                                </div>
                                <h3 data-test="subtitle-bidprice">Bid Price</h3>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Gross</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPositionCalc.grossBidPrice | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Net</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPositionCalc.netBidPrice | traceableFormat}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="key-value-pair-column">
                            <div>
                                <h3 data-test="subtitle-summary">Summary</h3>
                                <div class="key-value-pair">
                                    <div class="key-value-item">% of fund held</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPositionCalc.percentageOfFundHeld | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Drawdown</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPositionCalc.drawdown | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">DPI</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPositionCalc.dpi | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">TVPI</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPositionCalc.tvpi | traceableFormat}}</div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Seller Multiple TVPI</div>
                                    <div class="key-value-item">{{fundSellerPosition.sellerPositionCalc.sellerMultipleTVPI | traceableFormat}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </ng-container>
        <mat-card-actions class="detail-card-actions">
            <button (click)="editMode()"
                    *ngIf="!isEditable"
                    [disabled]="(isEditDisabled$ | async)!"
                    color="primary"
                    data-test="edit-button"
                    mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
            <ng-container *ngIf="fundValuationId$ | async as fundValuationId">
                <button (click)="save(dealId, fundValuationId)"
                        *ngIf="isEditable"
                        [disabled]="sellerPositionForm.invalid"
                        color="primary"
                        data-test="save-button"
                        mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
            </ng-container>
            <button (click)="cancel()"
                    *ngIf="isEditable"
                    color="primary"
                    data-test="cancel-button"
                    mat-icon-button>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-card-actions>
    </ng-container>
    <ng-template #selectDealFirst>
        <mat-card-content class="detail-card-content" style="color: grey">
            Please select deal first!
        </mat-card-content>
    </ng-template>
</mat-card>
