<div class="key-value-pair">
    <div class="key-value-item">{{label}}</div>
    <ng-container *ngIf="isDataset">
        <mat-chip (click)="overwriteValue()" [ngClass]="getChipColor()">{{baselineValue}}</mat-chip>
    </ng-container>
    <div class="key-value-item">
        <div *ngIf="!isEditable">
            <mat-checkbox [checked]=value [disabled]="true"></mat-checkbox>
        </div>
        <mat-checkbox color="primary" *ngIf="isEditable" [formControl]="control"></mat-checkbox>
    </div>
</div>
