/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, exhaustMap, interval, map, of, startWith, switchMap, takeUntil, withLatestFrom} from "rxjs";
import {AccelexImportActions, AccelexImportLogActions, DatasetActions} from "./import.actions";
import {AccelexImportService} from "../services/accelex-import.service";
import {DatasetService} from "../services/dataset.service";
import {MergeDataset} from "../models/merge-dataset";
import {DealListActions} from "../../deal/store/deal.actions";
import {ImportInfo} from "../models/import-info";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {selectDatasets} from "./import.selectors";


export const loadImportFiles = createEffect(
    (actions$ = inject(Actions), accelexImportService = inject(AccelexImportService)) => {
        return actions$.pipe(
            ofType(AccelexImportActions.load),
            exhaustMap(() =>
                accelexImportService.getImportFiles().pipe(
                    map((fileNames) => AccelexImportActions.loaded({fileNames})),
                    catchError((error: { message: string }) =>
                        of(AccelexImportActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadDealAfterLoadingImportFiles = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AccelexImportActions.loaded),
            map(() => DealListActions.load())
        );
    },
    {functional: true}
);

export const startImportJob = createEffect(
    (actions$ = inject(Actions), accelexImportService = inject(AccelexImportService)) => {
        return actions$.pipe(
            ofType(AccelexImportActions.import),
            exhaustMap((action) => {
                const importInfo: ImportInfo = {files: action.filesNames, dealId: action.dealId};
                return accelexImportService.startImportJob(importInfo).pipe(
                    map(() => AccelexImportActions.importsubmitted()),
                    catchError((error: { message: string }) =>
                        of(AccelexImportActions.importerror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const pollDatasetAfterImport = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AccelexImportActions.importsubmitted),
            map(() => DatasetActions.startpolling())
        );
    },
    {functional: true}
);

export const loadDatasets = createEffect(
    (actions$ = inject(Actions), datasetService = inject(DatasetService)) => {
        return actions$.pipe(
            ofType(DatasetActions.load),
            exhaustMap(() =>
                datasetService.getDatasets().pipe(
                    map((datasets) => DatasetActions.loaded({datasets})),
                    catchError((error: { message: string }) =>
                        of(DatasetActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const stopPollingDatasetAfterLoaded = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DatasetActions.loaded),
            map(() => DatasetActions.stoppolling())
        );
    },
    {functional: true}
);

// Todo Test this effect
export const pollingDatasets = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), datasetService = inject(DatasetService)) => {
        return actions$.pipe(
            ofType(DatasetActions.startpolling),
            withLatestFrom(store$.select(selectDatasets)),
            switchMap(([action, originDatasets]) =>
                interval(2000).pipe( // Polling interval
                    startWith(0),
                    switchMap(() =>
                        datasetService.getDatasets().pipe(
                            map(newDatasets => {
                                if (newDatasets.length !== originDatasets.data.length) {
                                    return DatasetActions.loaded({datasets: newDatasets});
                                } else {
                                    return DatasetActions.polling();
                                }
                            }),
                            catchError((error: { message: string }) =>
                                of(DatasetActions.loaderror({errorMsg: error.message}))
                            )
                        )
                    ),
                    takeUntil(actions$.pipe(ofType(DatasetActions.stoppolling)))
                )
            )
        );
    },
    {functional: true}
);

export const mergeDatasets = createEffect(
    (actions$ = inject(Actions), datasetService = inject(DatasetService)) => {
        return actions$.pipe(
            ofType(DatasetActions.merge),
            exhaustMap((action) => {
                const mergeDataset: MergeDataset = {
                    sourceDataset: action.source,
                    targetDataset: action.target
                };
                return datasetService.mergeDatasets(mergeDataset).pipe(
                    map((dataset) => DatasetActions.setsourceandtarget({source: dataset, target: undefined})),
                    catchError((error: { message: string }) =>
                        of(DatasetActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

// Todo Test this effect
export const navigateAfterMergeDatasets = createEffect(
    (actions$ = inject(Actions), router = inject(Router)) => {
        return actions$.pipe(
            ofType(DatasetActions.setsourceandtarget),
            exhaustMap(() => router.navigate(["/funds"]))
        );
    },
    {
        dispatch: false,
        functional: true
    }
);

export const pollAfterMergeDataset = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DatasetActions.setsourceandtarget),
            map(() => DatasetActions.startpolling())
        );
    },
    {functional: true}
);

export const deleteDataset = createEffect(
    (actions$ = inject(Actions), datasetService = inject(DatasetService)) => {
        return actions$.pipe(
            ofType(DatasetActions.delete),
            exhaustMap((action) => {
                return datasetService.deleteDataset(action.dataset).pipe(
                    map((dataset) => DatasetActions.deleted({dataset})),
                    catchError((error) =>
                        of(DatasetActions.loaderror({errorMsg: error}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const pollAfterDeleteDataset = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DatasetActions.deleted),
            map(() => DatasetActions.startpolling())
        );
    },
    {functional: true}
);

export const loadImportLogs = createEffect(
    (actions$ = inject(Actions), accelexImportService = inject(AccelexImportService)) => {
        return actions$.pipe(
            ofType(AccelexImportLogActions.loadall),
            exhaustMap(() =>
                accelexImportService.getImportLogs().pipe(
                    map((importLogs) => AccelexImportLogActions.loadedall({importLogs})),
                    catchError((error: { message: string }) =>
                        of(AccelexImportLogActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const stopPollingAtclearState = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DatasetActions.clearstate),
            map(() => DatasetActions.stoppolling())
        );
    },
    {functional: true}
);
