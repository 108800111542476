import {createReducer, on} from "@ngrx/store";
import {ExitsActions} from "../../asset/store/asset/asset.actions";
import {Fund} from "../models/fund";
import {FundInvestment} from "../models/fund-investement";
import {FundValuation} from "../models/fund-valuation";
import {
    FundActions,
    FundDetailActions,
    FundInvestmentActions,
    FundListActions,
    FundNameActions,
    FundNoteActions,
    FundReportActions,
    FundSellerPositionActions,
    FundValuationActions,
    FundValuationAggregatedCashflowAction,
    FundValuationScenarioActions,
    PartnershipInvestmentActions,
    ReturnSummaryActions,
    UndrawnValuationActions
} from "./fund.actions";
import {FundState} from "./fund.state";
import {EMPTY_BOOLEAN, EMPTY_CODE, EMPTY_MODEL_DATE, EMPTY_MONEY, EMPTY_MULTIPLE, EMPTY_PERCENT, EMPTY_TEXT} from "../../shared/model/traceable";
import {EMPTY_PERIOD} from "../../shared/model/period";
import {FundReport} from "../models/fund-report";
import {PartnershipInvestment} from "../models/partnership-investment";
import {FundValuationScenario} from "../models/fund-valuation-scenario";
import {PartnershipInvestmentRecord} from "../models/partnership-investment-record";
import {EMPTY_AGGREGATED_FUND_CASHFLOWS, EMPTY_FUND_VALUATION_CASHFLOWS} from "../models/aggregated-fund-cashflows";
import {SellerPositionActions} from "../../deal/store/deal.actions";
import {FundReturnSummary} from "../models/fund-return-summary";
import {CashflowWeightedCase} from "../models/cashflow-weighted-case";
import {CurrentOrRealizedInvestmentRecord} from "../models/current-or-realized-investment-record";
import {PerformanceTotals} from "../models/performance-totals";

export const fundStoreFeatureKey = "valumize/fund";

export const EMPTY_FUND: Fund = {
    id: undefined,
    name: EMPTY_TEXT,
    generalPartner: undefined,
    currencyIso: EMPTY_CODE,
    stage: EMPTY_CODE,
    regions: [],
    country: EMPTY_CODE,
    vintageYear: EMPTY_MODEL_DATE,
    purchaseYear: EMPTY_MODEL_DATE,
    endYear: EMPTY_MODEL_DATE,
    size: EMPTY_MONEY,
    mainFundSize: EMPTY_MONEY,
    managementFee: EMPTY_PERCENT,
    managementFeeCalculationMethod: EMPTY_CODE,
    carry: EMPTY_PERCENT,
    hurdleRate: EMPTY_PERCENT,
    dataset: "master",
    mergeTargetId: undefined,
    masterSystemId: undefined
};

export const EMPTY_FUND_REPORT: FundReport = {
    id: undefined,
    reportDate: EMPTY_MODEL_DATE,
    totalOfUnrealizedAssets: EMPTY_MONEY,
    gpCarryReserve: EMPTY_MONEY,
    netCurrentAssets: EMPTY_MONEY,
    netCurrentAssetsDate: EMPTY_MODEL_DATE,
    totalNav: EMPTY_MONEY,
    totalRemainingCosts: EMPTY_MONEY,
    unrealizedTVPI: EMPTY_MULTIPLE,
    totalTVPI: EMPTY_MULTIPLE,
    totalDrawDowns: EMPTY_MONEY,
    totalDistributions: EMPTY_MONEY,
    reportedUndrawn: EMPTY_MONEY,
    totalUndrawn: EMPTY_MONEY,
    recallableDistributions: EMPTY_MONEY,
    totalCarryPaid: EMPTY_MONEY,
    carryPaidToDate: EMPTY_MONEY,
    IRR: EMPTY_PERCENT,
    dataset: "master",
    mergeTargetId: undefined
};

export const EMPTY_FUND_VALUATION: FundValuation = {
    id: undefined,
    reportDate: EMPTY_MODEL_DATE,
    closingDate: EMPTY_MODEL_DATE,
    finalBidPrice: EMPTY_MONEY,
    bidPriceAdjustment: EMPTY_MONEY,
    percentageToBeDrawn: EMPTY_PERCENT,
    fundAssetValuations: []
};

export const EMPTY_FUND_VALUATION_SCENARIO: FundValuationScenario = {
    id: undefined,
    probability: EMPTY_PERCENT,
    scenario: EMPTY_CODE,
    undrawnMultiple: EMPTY_MULTIPLE,
    grossIRR: EMPTY_PERCENT,
    netPerformanceTvpi: EMPTY_MULTIPLE,
    netPerformanceIrr: EMPTY_PERCENT,
    netPerformancePreCarryTvpi: EMPTY_MULTIPLE,
    netPerformancePreCarryIrr: EMPTY_PERCENT,
    investmentPerformanceTvpi: EMPTY_MULTIPLE,
    investmentPerformanceIrr: EMPTY_PERCENT,
    grossPerformanceTvpi: EMPTY_MULTIPLE,
    grossPerformanceIrr: EMPTY_PERCENT,
    navUplift: EMPTY_PERCENT,
    finalFundNTvpi: EMPTY_MULTIPLE,
    netCurrentAssets: EMPTY_MONEY,
    cashflows: []
};

export const EMPTY_FUND_INVESTMENT: FundInvestment = {
    id: undefined,
    assetId: -1,
    investmentDate: EMPTY_MODEL_DATE,
    ownershipStake: EMPTY_PERCENT,
    exitDate: EMPTY_MODEL_DATE,
    isRealized: EMPTY_BOOLEAN,
    fxRateFund: EMPTY_PERCENT,
    period: EMPTY_PERIOD,
    currencyIso: EMPTY_CODE,
    remainingCost: EMPTY_MONEY,
    mezzanine: EMPTY_MONEY,
    preferredEquity: EMPTY_MONEY,
    commonEquity: EMPTY_MONEY,
    nav: EMPTY_MONEY,
    realizedCost: EMPTY_MONEY,
    realizedGains: EMPTY_MONEY,
    totalRealized: EMPTY_MONEY,
    totalCost: EMPTY_MONEY,
    unrealizedTVPI: EMPTY_MULTIPLE,
    realizedTVPI: EMPTY_MULTIPLE,
    totalTVPI: EMPTY_MULTIPLE,
    totalIRR: EMPTY_PERCENT,
    realizedPercentageOfInvestment: EMPTY_PERCENT
};

export const EMPTY_PERFORMANCE_TOTALS: PerformanceTotals = {
    totalRealizedCost: EMPTY_MONEY,
    totalRealizedGains: EMPTY_MONEY,
    totalRealized: EMPTY_MONEY,
    totalCost: EMPTY_MONEY
};

export const EMPTY_PARTNERSHIP_INVESTMENT: PartnershipInvestment = {
    totalRemainingCost: EMPTY_MONEY,
    totalGpNAV: EMPTY_MONEY,
    totalUnrealizedTVPI: EMPTY_MULTIPLE,
    totalTVPI: EMPTY_MULTIPLE,
    totalBid: EMPTY_MONEY,
    totalDiscountToNAV: EMPTY_PERCENT,
    totalBidPercentage: EMPTY_PERCENT,
    totalNavPercentage: EMPTY_PERCENT,
    gpCarryReserve: EMPTY_MONEY,
    netCurrentAssets: EMPTY_MONEY,
    netCurrentAssetsDate: EMPTY_MODEL_DATE,
    bidPriceAdjustment: EMPTY_MONEY,
    totalOfUnrealizedAssets: EMPTY_MONEY,
    totalExitCashflowsLow: EMPTY_MONEY,
    totalExitCashflowsBase: EMPTY_MONEY,
    totalExitCashflowsHigh: EMPTY_MONEY,
    realizedInvestmentsTotals: EMPTY_PERFORMANCE_TOTALS,
    currentInvestmentsTotals: EMPTY_PERFORMANCE_TOTALS,
    currentAndRealizedInvestmentsTotals: EMPTY_PERFORMANCE_TOTALS,
    realizedInvestmentRecords: [],
    currentInvestmentRecords: [],
    netCurrentAssetsLow: EMPTY_MONEY,
    netCurrentAssetsBase: EMPTY_MONEY,
    netCurrentAssetsHigh: EMPTY_MONEY,
    partnershipInvestmentRecords: []
};

export const EMPTY_PARTNERSHIP_INVESTMENT_RECORD: PartnershipInvestmentRecord = {
    assetId: -1,
    fundInvestmentId: -1,
    assetValuationId: undefined,
    company: EMPTY_TEXT,
    activityIndustry: EMPTY_TEXT,
    country: EMPTY_CODE,
    gpIndication: EMPTY_CODE,
    investmentDate: EMPTY_MODEL_DATE,
    exitDateAssumption: EMPTY_MODEL_DATE,
    effectiveExitDate: EMPTY_MODEL_DATE,
    isRealized: EMPTY_BOOLEAN,
    percentageHeld: EMPTY_PERCENT,
    remainingCost: EMPTY_MONEY,
    gpNAV: EMPTY_MONEY,
    navMultiple: EMPTY_MULTIPLE,
    realizedTVPI: EMPTY_MULTIPLE,
    unrealizedTVPI: EMPTY_MULTIPLE,
    totalTVPI: EMPTY_MULTIPLE,
    impliedCompanyEV: EMPTY_MONEY,
    basisForValuation: EMPTY_CODE,
    discountRate: EMPTY_PERCENT,
    selectedBid: EMPTY_MONEY,
    discountToNAV: EMPTY_PERCENT,
    percentageOfTotalBid: EMPTY_PERCENT,
    percentageOfTotalNAV: EMPTY_PERCENT,
    stabilityOfCFs: EMPTY_CODE,
    cashflowLow: EMPTY_MONEY,
    cashflowBase: EMPTY_MONEY,
    cashflowHigh: EMPTY_MONEY,
};

export const EMPTY_CURRENT_OR_REALIZED_INVESTMENT_RECORD: CurrentOrRealizedInvestmentRecord = {
    assetId: -1,
    fundInvestmentId: -1,
    company: EMPTY_TEXT,
    activityIndustry: EMPTY_TEXT,
    country: EMPTY_CODE,
    gpIndication: EMPTY_CODE,
    investmentDate: EMPTY_MODEL_DATE,
    effectiveExitDate: EMPTY_MODEL_DATE,
    isRealized: EMPTY_BOOLEAN,
    percentageHeld: EMPTY_PERCENT,
    holdingPeriodInYears: -1,
    realizedCost: EMPTY_MONEY,
    realizedGains: EMPTY_MONEY,
    totalCost: EMPTY_MONEY,
    totalRealized: EMPTY_MONEY
};

export const EMPTY_CASHFLOW_WEIGHTED_CASE: CashflowWeightedCase = {
    probability: EMPTY_PERCENT,
    netPerformanceTvpi: EMPTY_MULTIPLE,
    netPerformanceIrr: EMPTY_PERCENT,
    netPerformancePreCarryTvpi: EMPTY_MULTIPLE,
    netPerformancePreCarryIrr: EMPTY_PERCENT,
    investmentPerformanceTvpi: EMPTY_MULTIPLE,
    investmentPerformanceIrr: EMPTY_PERCENT,
    grossPerformanceTvpi: EMPTY_MULTIPLE,
    grossPerformanceIrr: EMPTY_PERCENT,
    navUplift: EMPTY_PERCENT,
    finalFundNTvpi: EMPTY_MULTIPLE
};

export const EMPTY_RETURN_SUMMARY: FundReturnSummary = {
    cashflowWeightedCase: EMPTY_CASHFLOW_WEIGHTED_CASE,
    scenarios: []
};

export const INITIAL_STATE: FundState = {
    funds: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedFund: {
        data: EMPTY_FUND,
        status: "INIT",
        errorMessage: ""
    },
    baselineFund: {
        data: EMPTY_FUND,
        status: "INIT",
        errorMessage: ""
    },
    selectedFundReportsForFund: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    baselineFundReportsForBaselineFund: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedFundReport: {
        data: EMPTY_FUND_REPORT,
        status: "INIT",
        errorMessage: ""
    },
    calculatedFundReport: {
        data: EMPTY_FUND_REPORT,
        status: "INIT",
        errorMessage: ""
    },
    baselineFundReport: {
        data: EMPTY_FUND_REPORT,
        status: "INIT",
        errorMessage: ""
    },
    selectedFundValuationsForFund: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedFundValuation: {
        data: EMPTY_FUND_VALUATION,
        status: "INIT",
        errorMessage: ""
    },
    calculatedFundValuation: {
        data: EMPTY_FUND_VALUATION,
        status: "INIT",
        errorMessage: ""
    },
    selectedFundValuationScenarios: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    calculatedFundValuationScenarios: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedFundInvestmentsForFundReport: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedFundInvestment: {
        data: EMPTY_FUND_INVESTMENT,
        status: "INIT",
        errorMessage: ""
    },
    selectedFundNotes: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedFundValuationCashFlows: EMPTY_FUND_VALUATION_CASHFLOWS,
    partnershipInvestments: {
        data: EMPTY_PARTNERSHIP_INVESTMENT,
        status: "INIT",
        errorMessage: ""
    },
    returnSummary: {
        data: EMPTY_RETURN_SUMMARY,
        status: "INIT",
        errorMessage: ""
    },
    fundForms: {
        fundNameIsEditable: false,
        fundDetailIsEditable: false,
        fundReportDetailsIsEditable: false,
        partnershipInvestmentIsEditable: false,
        undrawnValuationIsEditable: false,
        fundSellerPositionIsEditable: false
    }
};

export const fundReducer = createReducer(
    INITIAL_STATE,
    on(FundActions.clearfundstate, (): FundState => INITIAL_STATE),

    // Fund List
    on(FundListActions.load, (state): FundState => ({
        ...state,
        funds: {
            ...state.funds,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundListActions.loaded, (state, action): FundState => ({
        ...state,
        funds: {
            data: action.funds,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundListActions.loaderror, (state, action): FundState => ({
        ...state,
        funds: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Fund
    on(FundActions.load, (): FundState => INITIAL_STATE),
    on(FundActions.create, (state): FundState => ({
        ...state,
        selectedFund: {
            ...INITIAL_STATE.selectedFund,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundActions.load, (state): FundState => ({
        ...state,
        selectedFund: {
            ...INITIAL_STATE.selectedFund,
            ...{
                status: "LOADING"
            }
        },
        selectedFundNotes: {
            ...state.selectedFundNotes,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundActions.loaded, (state, action): FundState => ({
        ...state,
        selectedFund: {
            data: action.fund,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundActions.loadbaseline, (state): FundState => ({
        ...state,
        baselineFund: {
            ...INITIAL_STATE.baselineFund,
            ...{
                status: "LOADING"
            }
        },
    })),
    on(FundActions.loadedbaseline, (state, action): FundState => ({
        ...state,
        baselineFund: {
            data: action.fund,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundActions.savedloaded, (state, action): FundState => ({
        ...state,
        selectedFund: {
            data: action.fund,
            status: "SAVED",
            errorMessage: ""
        }
    })),
    on(FundActions.loaderror, (state, action): FundState => ({
        ...state,
        selectedFund: {
            data: EMPTY_FUND,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Fund Name
    on(FundNameActions.edit, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            fundNameIsEditable: true
        }
    })),
    on(FundNameActions.save, (state): FundState => ({
        ...state,
        selectedFund: {
            ...state.selectedFund,
            status: "LOADING"
        },
        fundForms: {
            ...state.fundForms,
            fundNameIsEditable: false
        }
    })),
    on(FundNameActions.cancel, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            fundNameIsEditable: false
        }
    })),

    // Fund Detail
    on(FundDetailActions.edit, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            fundDetailIsEditable: true
        }
    })),
    on(FundDetailActions.save, (state): FundState => ({
        ...state,
        selectedFund: {
            ...state.selectedFund,
            status: "LOADING"
        },
        fundForms: {
            ...state.fundForms,
            fundDetailIsEditable: false
        }
    })),
    on(FundDetailActions.cancel, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            fundDetailIsEditable: false
        }
    })),

    // Fund Notes
    on(FundNoteActions.loaded, (state, action): FundState => ({
        ...state,
        selectedFundNotes: {
            data: action.notes,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundNoteActions.loaderror, (state, action): FundState => ({
        ...state,
        selectedFundNotes: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),
    on(FundNoteActions.save, (state): FundState => ({
        ...state,
        selectedFundNotes: {
            ...state.selectedFundNotes,
            status: "LOADING"
        }
    })),
    on(FundNoteActions.delete, (state): FundState => ({
        ...state,
        selectedFundNotes: {
            ...state.selectedFundNotes,
            status: "LOADING"
        }
    })),

    // Fund Report
    on(FundReportActions.edit, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            fundReportDetailsIsEditable: true
        }
    })),
    on(FundReportActions.save, (state, action): FundState => ({
        ...state,
        selectedFundReport: {
            ...state.selectedFundReport,
            status: "LOADING"
        },
        calculatedFundReport: {
            ...state.calculatedFundReport,
            status: "LOADING"
        },
        fundForms: {
            ...state.fundForms,
            fundReportDetailsIsEditable: false
        }
    })),
    on(FundReportActions.calcsuccess, (state, action): FundState => ({
        ...state,
        calculatedFundReport: {
            data: action.fundReport,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundReportActions.cancel, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            fundReportDetailsIsEditable: false
        }
    })),
    on(FundReportActions.create, (state): FundState => ({
        ...state,
        selectedFundReport: {
            ...INITIAL_STATE.selectedFundReport,
            status: "LOADING"
        },
        calculatedFundReport: {
            ...INITIAL_STATE.calculatedFundReport,
            status: "LOADING"
        }
    })),
    on(FundReportActions.load, (state): FundState => ({
        ...state,
        selectedFundReport: {
            ...state.selectedFundReport,
            status: "LOADING"
        },
        calculatedFundReport: {
            ...state.calculatedFundReport,
            status: "LOADING"
        }
    })),
    on(FundReportActions.loaded, (state, action): FundState => ({
        ...state,
        selectedFundReport: {
            data: action.fundReport,
            status: "LOADED",
            errorMessage: ""
        },
        calculatedFundReport: {
            data: action.fundReport,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundReportActions.loadbaseline, (state): FundState => ({
        ...state,
        baselineFundReport: {
            ...INITIAL_STATE.baselineFundReport,
            ...{
                status: "LOADING"
            }
        },
    })),
    on(FundReportActions.loadedbaseline, (state, action): FundState => ({
        ...state,
        baselineFundReport: {
            data: action.fundReport,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundReportActions.loadall, (state): FundState => ({
        ...state,
        selectedFundReportsForFund: {
            ...state.selectedFundReportsForFund,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundReportActions.loadedall, (state, action): FundState => ({
        ...state,
        selectedFundReportsForFund: {
            data: action.reports,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundReportActions.loadedallbaseline, (state, action): FundState => ({
        ...state,
        baselineFundReportsForBaselineFund: {
            data: action.reports,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundReportActions.clearbaseline, (state, action): FundState => ({
        ...state,
        baselineFundReport: {
            data: EMPTY_FUND_REPORT,
            status: "INIT",
            errorMessage: ""
        }
    })),
    on(FundReportActions.loaderror, (state, action): FundState => ({
        ...state,
        selectedFundReport: {
            data: EMPTY_FUND_REPORT,
            status: "ERROR",
            errorMessage: action.errorMsg
        },
        calculatedFundReport: {
            data: EMPTY_FUND_REPORT,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Fund Valuation
    on(FundValuationActions.create, (state): FundState => ({
        ...state,
        selectedFundValuation: {
            ...INITIAL_STATE.selectedFundValuation,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundValuationActions.load, (state): FundState => ({
        ...state,
        selectedFundValuation: {
            ...state.selectedFundValuation,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundValuationActions.loaded, (state, action): FundState => ({
        ...state,
        selectedFundValuation: {
            data: action.fundValuation,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundValuationActions.savedloaded, (state, action): FundState => ({
        ...state,
        selectedFundValuation: {
            data: action.fundValuation,
            status: "SAVED",
            errorMessage: ""
        }
    })),
    on(FundValuationActions.loadall, (state): FundState => ({
        ...state,
        selectedFundValuationsForFund: {
            ...state.selectedFundValuationsForFund,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundValuationActions.loadedall, (state, action): FundState => ({
        ...state,
        selectedFundValuationsForFund: {
            data: action.valuations,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundValuationActions.loaderror, (state, action): FundState => ({
        ...state,
        selectedFundValuation: {
            data: EMPTY_FUND_VALUATION,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Fund Valuation Scenarios
    on(FundValuationScenarioActions.loadall, (state): FundState => ({
        ...state,
        selectedFundValuationScenarios: {
            ...state.selectedFundValuationScenarios,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundValuationScenarioActions.loadedall, (state, action): FundState => ({
        ...state,
        selectedFundValuationScenarios: {
            data: action.scenarios,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundValuationScenarioActions.loaderror, (state, action): FundState => ({
        ...state,
        selectedFundValuationScenarios: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Fund Valuation Cashflows (Aggregated)
    on(FundValuationAggregatedCashflowAction.load, (state, action): FundState => ({
        ...state,
        selectedFundValuationCashFlows:
            state.selectedFundValuationCashFlows.map(scenarioCashFlows =>
                scenarioCashFlows.scenarioType === action.scenarioType ? {
                    scenarioType: scenarioCashFlows.scenarioType,
                    aggregatedFundCashFlows: {
                        data: EMPTY_AGGREGATED_FUND_CASHFLOWS,
                        status: "LOADING",
                        errorMessage: ""
                    }
                } : scenarioCashFlows)
    })),
    on(FundValuationAggregatedCashflowAction.loaded, (state, action): FundState => ({
        ...state,
        selectedFundValuationCashFlows:
            state.selectedFundValuationCashFlows.map(scenarioCashFlows =>
                scenarioCashFlows.scenarioType === action.scenarioType ? {
                    scenarioType: scenarioCashFlows.scenarioType,
                    aggregatedFundCashFlows: {
                        data: action.aggregatedCashFlows,
                        status: "LOADED",
                        errorMessage: ""
                    }
                } : scenarioCashFlows)
    })),
    on(FundValuationAggregatedCashflowAction.loaderror, (state, action): FundState => ({
            ...state,
            selectedFundValuationCashFlows:
                state.selectedFundValuationCashFlows.map(scenarioCashFlows =>
                    scenarioCashFlows.scenarioType === action.scenarioType ?
                        {
                            scenarioType: action.scenarioType,
                            aggregatedFundCashFlows: {
                                data: EMPTY_AGGREGATED_FUND_CASHFLOWS,
                                status: "ERROR",
                                errorMessage: action.errorMsg
                            }
                        }
                        : scenarioCashFlows)
        })
    ),

    // Fund Investment
    on(FundInvestmentActions.load, (state): FundState => ({
        ...state,
        selectedFundInvestment: {
            ...state.selectedFundInvestment,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundInvestmentActions.save, (state): FundState => ({
        ...state,
        selectedFundInvestment: {
            ...state.selectedFundInvestment,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundInvestmentActions.loaded, (state, action): FundState => ({
        ...state,
        selectedFundInvestment: {
            data: action.fundInvestment,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(FundInvestmentActions.savedloaded, (state, action): FundState => ({
        ...state,
        selectedFundInvestment: {
            data: action.fundInvestment,
            status: "SAVED",
            errorMessage: ""
        }
    })),
    on(FundInvestmentActions.saveall, (state): FundState => ({
        ...state,
        selectedFundInvestmentsForFundReport: {
            ...state.selectedFundInvestmentsForFundReport,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundInvestmentActions.loaderror, (state, action): FundState => ({
        ...state,
        selectedFundInvestmentsForFundReport: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),
    on(FundInvestmentActions.loadall, (state): FundState => ({
        ...state,
        selectedFundInvestmentsForFundReport: {
            ...state.selectedFundInvestmentsForFundReport,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(FundInvestmentActions.loadedall, (state, action): FundState => ({
        ...state,
        selectedFundInvestmentsForFundReport: {
            data: action.fundInvestments,
            status: "LOADED",
            errorMessage: ""
        }
    })),

    // Partnership Investment
    on(PartnershipInvestmentActions.load, (state): FundState => ({
        ...state,
        partnershipInvestments: {
            ...state.partnershipInvestments,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(PartnershipInvestmentActions.loaded, (state, action): FundState => ({
        ...state,
        partnershipInvestments: {
            data: action.partnershipInvestment,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(PartnershipInvestmentActions.loaderror, (state, action): FundState => ({
        ...state,
        partnershipInvestments: {
            data: EMPTY_PARTNERSHIP_INVESTMENT,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),
    on(PartnershipInvestmentActions.edit, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            partnershipInvestmentIsEditable: true
        }
    })),
    on(PartnershipInvestmentActions.cancel, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            partnershipInvestmentIsEditable: false
        }
    })),
    on(PartnershipInvestmentActions.save, (state): FundState => ({
        ...state,
        selectedFundReport: {
            ...state.selectedFundReport,
            status: "LOADING"
        },
        selectedFundValuation: {
            ...state.selectedFundValuation,
            status: "LOADING"
        },
        fundForms: {
            ...state.fundForms,
            partnershipInvestmentIsEditable: false
        }
    })),

    // Exits
    on(ExitsActions.save, (state): FundState => ({
        ...state,
        selectedFundInvestment: {
            ...state.selectedFundInvestment,
            status: "LOADING"
        }
    })),

    // Undrawn Valuation
    on(UndrawnValuationActions.edit, (state): FundState => ({
        ...state,
        calculatedFundValuation: state.selectedFundValuation,
        calculatedFundValuationScenarios: state.selectedFundValuationScenarios,
        fundForms: {
            ...state.fundForms,
            undrawnValuationIsEditable: true
        }
    })),
    on(UndrawnValuationActions.save, (state): FundState => ({
        ...state,
        selectedFundValuation: {
            ...state.selectedFundValuation,
            status: "LOADING"
        },
        selectedFundValuationScenarios: {
            ...state.selectedFundValuationScenarios,
            status: "LOADING"
        },
        fundForms: {
            ...state.fundForms,
            undrawnValuationIsEditable: false
        }
    })),
    on(UndrawnValuationActions.cancel, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            undrawnValuationIsEditable: false
        }
    })),
    on(UndrawnValuationActions.calc, (state, action): FundState => ({
        ...state,
        calculatedFundValuation: {
            data: action.fundValuation,
            status: "LOADING",
            errorMessage: ""
        },
        calculatedFundValuationScenarios: {
            data: action.scenarios,
            status: "LOADING",
            errorMessage: ""
        }
    })),
    on(UndrawnValuationActions.calcsuccess, (state, action): FundState => ({
        ...state,
        calculatedFundValuation: {
            data: action.fundValuation,
            status: "LOADED",
            errorMessage: ""
        },
        calculatedFundValuationScenarios: {
            data: action.scenarios,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(UndrawnValuationActions.calcerror, (state, action): FundState => ({
        ...state,
        calculatedFundValuation: {
            data: EMPTY_FUND_VALUATION,
            status: "ERROR",
            errorMessage: action.errorMsg
        },
        calculatedFundValuationScenarios: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Seller Position (Fund)
    on(FundSellerPositionActions.edit, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            fundSellerPositionIsEditable: true
        }
    })),
    on(FundSellerPositionActions.cancel, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            fundSellerPositionIsEditable: false
        }
    })),
    on(SellerPositionActions.savedloaded, (state): FundState => ({
        ...state,
        fundForms: {
            ...state.fundForms,
            fundSellerPositionIsEditable: false
        }
    })),

    // Return Summary
    on(ReturnSummaryActions.calc, (state): FundState => ({
        ...state,
        returnSummary: {
            ...state.returnSummary,
            status: "LOADING"
        }
    })),
    on(ReturnSummaryActions.calcsuccess, (state, action): FundState => ({
        ...state,
        returnSummary: {
            data: action.returnSummary,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(ReturnSummaryActions.calcerror, (state, action): FundState => ({
        ...state,
        returnSummary: {
            data: EMPTY_RETURN_SUMMARY,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    }))
);
