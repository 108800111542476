import {NgModule} from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {AssetOverviewPageComponent} from "./asset-overview-page/asset-overview-page.component";
import {AssetDetailsPageComponent} from "./asset-details-page/asset-details-page.component";
import {SharedModule} from "../shared/shared.module";
import {MatIconModule} from "@angular/material/icon";
import {BrowserModule} from "@angular/platform-browser";
import {AppRoutingModule} from "../app-routing.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {AssetDescriptionComponent} from "./asset-details-page/asset-description/asset-description.component";
import {AssetGeneralInformationComponent} from "./asset-details-page/asset-general-information/asset-general-information.component";
import {AssetEndMarketsCustomersComponent} from "./asset-details-page/asset-end-markets-customers/asset-end-markets-customers.component";
import {AssetSwotComponent} from "./asset-details-page/asset-swot/asset-swot.component";
import {AssetExitInformationComponent} from "./asset-details-page/asset-exit-information/asset-exit-information.component";
import {AssetGpValuationComponent} from "./asset-details-page/asset-gp-valuation/asset-gp-valuation.component";
import {StoreModule} from "@ngrx/store";
import {assetReducer, assetStoreFeatureKey} from "./store/asset.reducer";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatChipsModule} from "@angular/material/chips";
import {MatTableModule} from "@angular/material/table";
import {AssetFinDataHistoryComponent} from "./asset-details-page/asset-fin-data-history/asset-fin-data-history.component";
import {fundReducer, fundStoreFeatureKey} from "../fund/store/fund.reducer";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatExpansionModule} from "@angular/material/expansion";
import {AssetNewDialogComponent} from "./asset-new-dialog/asset-new-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {AssetDiscountMultipleValuationComponent} from "./asset-details-page/asset-discount-multiple-valuation/asset-discount-multiple-valuation.component";
import {TraceableFormatPipe} from "../shared/pipes/traceable-format/traceable-format.pipe";
import {AssetValuationNewDialogComponent} from "./asset-details-page/asset-valuation-new-dialog/asset-valuation-new-dialog.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {AssetHistoryPeriodDialogComponent} from "./asset-details-page/asset-fin-data-history/asset-history-period-dialog/asset-history-period-dialog.component";
import {AssetShareholderStructureComponent} from "./asset-details-page/asset-shareholder-structure/asset-shareholder-structure.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {SearchModule} from "../search/search.module";
import {BASE_PATH} from "./generated-sources/api";


@NgModule({
    declarations: [
        AssetOverviewPageComponent,
        AssetDetailsPageComponent,
        AssetDescriptionComponent,
        AssetGeneralInformationComponent,
        AssetEndMarketsCustomersComponent,
        AssetSwotComponent,
        AssetExitInformationComponent,
        AssetGpValuationComponent,
        AssetFinDataHistoryComponent,
        AssetNewDialogComponent,
        AssetDiscountMultipleValuationComponent,
        AssetValuationNewDialogComponent,
        AssetHistoryPeriodDialogComponent,
        AssetShareholderStructureComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatTableModule,
        MatToolbarModule,
        ReactiveFormsModule,
        SharedModule,
        StoreModule.forFeature(assetStoreFeatureKey, assetReducer),
        StoreModule.forFeature(fundStoreFeatureKey, fundReducer),
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSortModule,
        NgOptimizedImage,
        SearchModule
    ],
    exports: [
        AssetGeneralInformationComponent,
        AssetGpValuationComponent,
        AssetDiscountMultipleValuationComponent,
        AssetExitInformationComponent
    ],
    providers: [
        TraceableFormatPipe,
        {provide: BASE_PATH, useValue: ".."}
    ]
})
export class AssetModule {
}
