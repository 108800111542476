import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FundOverviewPageComponent} from "./fund-overview-page/fund-overview-page.component";
import {FundDetailsPageComponent} from "./fund-details-page/fund-details-page.component";
import {StoreModule} from "@ngrx/store";
import {fundReducer, fundStoreFeatureKey} from "./store/fund.reducer";
import {SharedModule} from "../shared/shared.module";
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {AppRoutingModule} from "../app-routing.module";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatChipsModule} from "@angular/material/chips";
import {FundSellerPositionComponent} from "./fund-details-page/fund-seller-position/fund-seller-position.component";
import {MatIconModule} from "@angular/material/icon";
import {FundDetailsComponent} from "./fund-details-page/fund-details/fund-details.component";
import {FundUndrawnValuationComponent} from "./fund-details-page/fund-undrawn-valuation/fund-undrawn-valuation.component";
import {FundPartnershipInvestmentsComponent} from "./fund-details-page/fund-partnership-investments/fund-partnership-investments.component";
import {FundTreemapActualComponent} from "./fund-details-page/fund-treemap-actual/fund-treemap-actual.component";
import {FundReturnSummaryComponent} from "./fund-details-page/fund-return-summary/fund-return-summary.component";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {FundNewDialogComponent} from "./fund-new-dialog/fund-new-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import {FundValuationNewDialogComponent} from "./fund-details-page/fund-valuation-new-dialog/fund-valuation-new-dialog.component";
import {FundInvestmentNewDialogComponent} from "./fund-details-page/fund-investment-new-dialog/fund-investment-new-dialog.component";
import {FundReportNewDialogComponent} from "./fund-details-page/fund-report-new-dialog/fund-report-new-dialog.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FundCashflowsComponent} from "./fund-details-page/fund-cashflows/fund-cashflows.component";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FundCashflowTableComponent} from "./fund-details-page/fund-cashflows/fund-cashflow-table/fund-cashflow-table.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {FundReportDetailsComponent} from "./fund-details-page/fund-report-details/fund-report-details.component";
import {FundCashflowsGraphComponent} from "./fund-details-page/fund-cashflows-graph/fund-cashflows-graph.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {
    FundCurrentAndRealizedInvestmentsComponent
} from "./fund-details-page/fund-partnership-investments/fund-current-and-realized-investments/fund-current-and-realized-investments.component";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {BASE_PATH} from "./generated-sources/api";
import {SearchModule} from "../search/search.module";
import {FundReportMergeTargetDialogComponent} from "./fund-details-page/fund-report-merge-target-dialog/fund-report-merge-target-dialog.component";
import { AssetDetailsDialogComponent } from "./fund-details-page/fund-partnership-investments/asset-details-dialog/asset-details-dialog.component";
import {AssetModule} from "../asset/asset.module";


@NgModule({
    declarations: [
        FundOverviewPageComponent,
        FundDetailsPageComponent,
        FundSellerPositionComponent,
        FundDetailsComponent,
        FundUndrawnValuationComponent,
        FundPartnershipInvestmentsComponent,
        FundTreemapActualComponent,
        FundCashflowsGraphComponent,
        FundReturnSummaryComponent,
        FundNewDialogComponent,
        FundValuationNewDialogComponent,
        FundInvestmentNewDialogComponent,
        FundReportNewDialogComponent,
        FundCashflowsComponent,
        FundCashflowTableComponent,
        FundReportDetailsComponent,
        FundCurrentAndRealizedInvestmentsComponent,
        FundReportMergeTargetDialogComponent,
        AssetDetailsDialogComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSidenavModule,
        MatTableModule,
        ReactiveFormsModule,
        SharedModule,
        StoreModule.forFeature(fundStoreFeatureKey, fundReducer),
        MatDialogModule,
        MatDatepickerModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatSortModule,
        MatPaginatorModule,
        SearchModule,
        AssetModule
    ],
    providers: [
        {provide: BASE_PATH, useValue: ".."}
    ]
})
export class FundModule {
}
