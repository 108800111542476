<ng-container *ngIf="(datasets$ | async) as datasetList">
    <mat-card class="detail-card">
        <mat-card-header class="detail-card-header">
            <mat-card-title data-test="title">Datasets</mat-card-title>
        </mat-card-header>
        <ng-container *ngIf="datasetList.data as datasets">
            <mat-card-content class="detail-card-content">
                <table [dataSource]="filterMasterDataset(datasets)" mat-table>
                    <ng-container matColumnDef="source" sticky>
                        <th *matHeaderCellDef mat-header-cell>Source</th>
                        <td *matCellDef="let element" mat-cell>{{element}}</td>
                    </ng-container>

                    <ng-container matColumnDef="arrow">
                        <th *matHeaderCellDef mat-header-cell></th>
                        <td *matCellDef="let element" mat-cell>
                            <mat-icon>arrow_right_alt</mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="target">
                        <th *matHeaderCellDef mat-header-cell>Target</th>
                        <td *matCellDef="let element" mat-cell>
                            <mat-form-field appearance="outline">
                                <mat-select [value]="defaultTargetDataset">
                                    <mat-option (onSelectionChange)="updateSourceTargetMap(element, $event)"
                                                *ngFor="let targetDataset of filterSourceDataset(datasets, element)" [value]="targetDataset">
                                        {{targetDataset}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="prepareForMerge">
                        <th *matHeaderCellDef mat-header-cell></th>
                        <td *matCellDef="let element" mat-cell>
                            <button
                                (click)="routeToMergePage(element)"
                                class="black-button"
                                data-test="prepare-for-merge"
                                mat-stroked-button>
                                Prepare for merge
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th *matHeaderCellDef mat-header-cell></th>
                        <td *matCellDef="let element" mat-cell>
                            <button
                                (click)="deleteDataset(element)"
                                class="overview-actions"
                                color="black"
                                data-test="delete"
                                mat-icon-button>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="columns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: columns" mat-row></tr>
                </table>
                <mat-expansion-panel [expanded]="panelOpenState" class="mat-expansion-panel">
                    <mat-expansion-panel-header class="mat-expansion-panel-header">
                        <mat-card-title data-test="title">Previous Accelex Imports</mat-card-title>
                    </mat-expansion-panel-header>
                    <valumize-accelex-import-logs></valumize-accelex-import-logs>
                </mat-expansion-panel>
            </mat-card-content>
        </ng-container>
        <mat-card-actions class="overview-actions">
            <button (click)="refreshDatasets()"
                    class="action-button-margin"
                    color="primary"
                    data-test="import-dataset"
                    mat-stroked-button>
                <mat-icon>refresh</mat-icon>
                Refresh
            </button>
            <button (click)="openImportDatasetDialog()"
                    color="primary"
                    data-test="import-dataset"
                    mat-stroked-button>
                <mat-icon>add</mat-icon>
                Import Dataset
            </button>
        </mat-card-actions>
    </mat-card>
</ng-container>
