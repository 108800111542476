import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {
    selectIsAnyAssetFormInEditMode,
    selectSelectedAssetId,
    selectShareholders,
    selectShareholdersForDate,
    selectShareholderStructureEdit
} from "../../store/asset/asset.selectors";
import {ShareholderActions} from "../../store/asset/asset.actions";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {selectSelectedFundReportReportDate} from "../../../fund/store/fund-report/fund-report.selectors";
import {map, Subscription} from "rxjs";
import {EMPTY_SHAREHOLDER} from "../../store/asset.reducer";
import {MatTableDataSource} from "@angular/material/table";
import {Shareholder} from "../../models/asset/shareholder";
import {DateUtil} from "../../../shared/utils/date-util";
import {DateTime} from "luxon";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";

@Component({
    selector: "valumize-asset-shareholder-structure",
    templateUrl: "./asset-shareholder-structure.component.html",
    styleUrls: ["./asset-shareholder-structure.component.scss"]
})
export class AssetShareholderStructureComponent implements OnInit, OnDestroy {

    selectedReportDate$ = this.store.select(selectSelectedFundReportReportDate);
    shareholderStructure$ = this.store.select(selectShareholdersForDate);
    shareholderStructureIsEditable$ = this.store.select(selectShareholderStructureEdit);
    allShareholders$ = this.store.select(selectShareholders);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);

    subscriptions: Subscription[] = [];

    persistedShareholders: Shareholder[] = [];
    reportDate?: string;
    assetId?: number;
    datepickerDisabled = false;
    editingDate = false;

    displayedColumns = ["shareholderName", "stake", "progressBar", "delete"];
    tableDataSource = new MatTableDataSource<any>();

    shareholdersForm: FormGroup = this.formBuilder.group({
        shareholders: this.formBuilder.array([])
    });

    constructor(private readonly store: Store, private readonly formBuilder: FormBuilder) {
    }

    get shareholders(): FormArray {
        return this.shareholdersForm.get("shareholders") as FormArray;
    }

    ngOnInit() {
        this.subscriptions.push(
            this.selectedReportDate$.pipe(map(reportDateState => {
                if (reportDateState.date && reportDateState.date !== "") {
                    this.reportDate = reportDateState.date;
                    this.datepickerDisabled = true;
                } else {
                    this.reportDate = DateUtil.isoDate();
                    this.datepickerDisabled = false;
                }
                this.loadShareholders();
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectShareholdersForDate).pipe(map(shareholders => {
                this.persistedShareholders = shareholders.data;
                const shareholderGroups = this.persistedShareholders.map(shareholder => this.formBuilder.group({
                    id: shareholder.id,
                    shareholderName: [shareholder.shareholderName.text, Validators.required],
                    stake: [DecimalFormatPipe.transformFractionToPercent(shareholder.stake.fraction ?? undefined), Validators.required],
                    source: [shareholder.source.code]
                }));
                this.shareholdersForm.setControl("shareholders", this.formBuilder.array(shareholderGroups));
                this.tableDataSource.data = this.shareholders.controls;
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectSelectedAssetId).pipe(map(assetId => {
                this.assetId = assetId;
                this.loadShareholders();
            })).subscribe());
    }

    loadShareholders() {
        if (this.reportDate && this.assetId) {
            this.store.dispatch(ShareholderActions.load({assetId: this.assetId, date: this.reportDate}));
        }
    }

    addShareholder() {
        const newShareholderGroup = this.formBuilder.group({
            id: [undefined],
            shareholderName: ["", Validators.required],
            stake: [0, Validators.required],
            source: ["MANUAL"]
        });
        this.shareholders.push(newShareholderGroup);
        this.tableDataSource.data = this.shareholders.controls;
    }

    removeShareholder(index: number): void {
        this.shareholders.removeAt(index);
        this.tableDataSource.data = this.shareholders.controls;
    }

    copyShareholderToCurrentDate(oldShareholder: Shareholder) {
        const newShareholderGroup = this.formBuilder.group({
            id: [undefined],
            shareholderName: [oldShareholder.shareholderName.text, Validators.required],
            stake: [DecimalFormatPipe.transformFractionToPercent(oldShareholder.stake.fraction), Validators.required],
            source: ["MANUAL"]
        });
        this.shareholders.push(newShareholderGroup);
        this.tableDataSource.data = this.shareholders.controls;
    };

    editMode = () => this.store.dispatch(ShareholderActions.edit());

    save() {
        if (this.shareholdersForm.valid && this.reportDate && this.assetId) {
            const shareholdersToSave: Shareholder[] = this.shareholders.value.map((shareholder: { id: number; shareholderName: string; stake: number }) => {
                if (!shareholder.id) { // New shareholder
                    return {
                        ...EMPTY_SHAREHOLDER,
                        shareholderName: {...EMPTY_SHAREHOLDER.shareholderName, text: shareholder.shareholderName},
                        stake: {...EMPTY_SHAREHOLDER.stake, fraction: DecimalFormatPipe.transformPercentToFraction(shareholder.stake)},
                        date: {...EMPTY_SHAREHOLDER.date, date: this.reportDate},
                        source: {...EMPTY_SHAREHOLDER.source, code: "MANUAL"}
                    };
                } else { // Existing shareholder
                    const persistedShareholder = this.persistedShareholders.find(p => p.id === shareholder.id);
                    return {
                        ...persistedShareholder,
                        shareholderName: {...persistedShareholder?.shareholderName, text: shareholder.shareholderName},
                        stake: {...persistedShareholder?.stake, fraction: DecimalFormatPipe.transformPercentToFraction(shareholder.stake)}
                    };
                }
            });

            this.store.dispatch(ShareholderActions.save({
                assetId: this.assetId,
                date: this.reportDate,
                shareholders: shareholdersToSave
            }));
        } else {
            console.error("Form is invalid or Report Date missing.");
        }
    }

    cancel() {
        this.store.dispatch(ShareholderActions.cancel());
        this.loadShareholders();
    }

    toggleEdit(): void {
        this.editingDate = !this.editingDate;
    }

    dateChanged(event: { value: Date }): void {
        if (event.value) {
            const localDate = DateTime.fromJSDate(event.value).startOf("day");
            this.reportDate = localDate.toISODate() ?? undefined;
        } else {
            this.reportDate = DateUtil.isoDate();
        }
        this.toggleEdit();
        this.loadShareholders();
    }

    calculateTotalStake(): number {
        return this.shareholders.controls.reduce((acc, control) => acc + parseFloat(control.get("stake")?.value), 0);
    }

    getStakeGap(): number {
        const totalStake = this.calculateTotalStake();
        return 100 - totalStake;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
