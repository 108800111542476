import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {FundInvestmentActions} from "../../store/fund.actions";
import {selectSelectedFundInvestmentsForFundReport} from "../../store/fund-investment/fund-investment.selectors";
import {selectAssetNameFromId, selectAssets} from "../../../asset/store/asset/asset.selectors";
import {AssetListActions} from "../../../asset/store/asset/asset.actions";
import {FundInvestment} from "../../models/fund-investement";
import {BehaviorSubject, combineLatest, map, Observable} from "rxjs";
import {Asset} from "../../../asset/models/asset/asset";
import {EMPTY_FUND_INVESTMENT} from "../../store/fund.reducer";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {selectSelectedFundReportReportDate} from "../../store/fund-report/fund-report.selectors";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {CodeTableEnum} from "../../../shared/model/code";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationComponent} from "../../../shared/components/confirmation/confirmation.component";
import {DateUtil} from "../../../shared/utils/date-util";

@Component({
    selector: "valumize-fund-investment-new-dialog",
    templateUrl: "./fund-investment-new-dialog.component.html",
    styleUrls: ["./fund-investment-new-dialog.component.scss"]
})
export class FundInvestmentNewDialogComponent implements OnInit {

    @Input() fundId?: number;
    @Input() fundReportId?: number;
    @Input() fundValuationId?: number;

    selectableAssetColumns = ["id", "name", "country", "currencyIso", "activityDescription", "gic", "add"];
    selectableAssetDataSource: MatTableDataSource<any> = new MatTableDataSource();
    codeTableCountry = CodeTableEnum.SHARED_COUNTRY;
    codeTableCurrency = CodeTableEnum.SHARED_CURRENCY;

    @ViewChild(MatSort, {static: true}) selectableAssetSort = new MatSort();

    partnershipInvestmentColumns = ["assetId", "assetName", "ownershipStake", "investmentDate", "remove"];

    reportDate$ = this.store.select(selectSelectedFundReportReportDate);

    fundInvestments: FundInvestment[] = [];
    allAssets: Asset[] = [];
    newInvestmentsForm: { [key: number]: FormGroup } = {};
    newInvestments: Set<number> = new Set();

    fundInvestmentsSubject = new BehaviorSubject<FundInvestment[]>(this.fundInvestments);
    fundInvestments$ = this.fundInvestmentsSubject.asObservable();

    updateFundInvestments(newInvestments: FundInvestment[]) {
        this.fundInvestments = newInvestments;
        this.fundInvestmentsSubject.next(newInvestments);
    }

    constructor(
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        private readonly dialog: MatDialog,
    ) {
        this.store.dispatch(AssetListActions.load());
    }

    ngOnInit() {
        if (!!this.fundId && !!this.fundReportId) {
            this.store.dispatch(FundInvestmentActions.loadall({fundId: this.fundId, fundReportId: this.fundReportId, fundValuationId: this.fundValuationId}));

            this.store.select(selectSelectedFundInvestmentsForFundReport).pipe(
                map((fundInvestments) => {
                    this.fundInvestments = fundInvestments.data;
                    this.fundInvestmentsSubject.next(this.fundInvestments);
                })).subscribe();

            combineLatest([this.fundInvestments$, this.store.select(selectAssets)]).pipe(
                map(([fundInvestments, assets]) => {
                    this.allAssets = assets;
                    this.selectableAssetDataSource.data = this.filterNotInvested(assets, fundInvestments);
                    this.selectableAssetDataSource.sort = this.selectableAssetSort;
                    this.selectableAssetDataSource.filterPredicate = (data: Asset, filter: string) => (data.name.text?.toLowerCase() ?? "").includes(filter);

                    this.selectableAssetDataSource.sortingDataAccessor = (item, property) => {
                        switch (property) {
                            case "name":
                                return item.name.text;
                            case "country":
                                return item.country.code;
                            case "currencyIso":
                                return item.currencyIso.code;
                            case "activityDescription":
                                return item.activityDescription;
                            case "gic":
                                return item.gic.text;
                            default:
                                return item[property as keyof Asset];
                        }
                    };
                    this.selectableAssetDataSource.sort.sort({
                        id: "name",
                        start: "desc",
                        disableClear: true
                    });
                })).subscribe();
        }
    }

    getAssetNameById(assetId: number): Observable<string> {
        return this.store.select(selectAssetNameFromId(assetId));
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.selectableAssetDataSource.filter = filterValue.trim().toLowerCase();
    }

    addFundInvestment(assetId: number) {
        const fundInvestmentToAdd: FundInvestment = {
            ...EMPTY_FUND_INVESTMENT,
            assetId
        };
        this.updateFundInvestments([...this.fundInvestments, fundInvestmentToAdd]);
        this.newInvestmentsForm[assetId] = this.formBuilder.group({
            investmentDate: this.formBuilder.control<Date | null>(null, {nonNullable: true, validators: Validators.required}),
            ownershipStake: this.formBuilder.control<number | null>(null, {nonNullable: true, validators: [Validators.required, Validators.max(100)]})
        });
        this.newInvestments.add(assetId);
    }

    removeFundInvestment(assetId: number) {
        if (this.newInvestments.has(assetId)) {
            this.performRemoveFundInvestment(assetId);
        } else {
            this.openConfirmDialog(() => this.performRemoveFundInvestment(assetId));
        }
    }

    openConfirmDialog(deleteAction: () => void) {
        const dialogRef = this.dialog.open(ConfirmationComponent);
        dialogRef.componentInstance.confirmMessage = "This will delete this fund investment and its associated asset valuation. Are you sure?";

        dialogRef.afterClosed().pipe(map(result => {
            if (result) {
                deleteAction();
            }
        })).subscribe();
    }

    performRemoveFundInvestment(assetId: number) {
        this.updateFundInvestments(this.fundInvestments.filter(i => i.assetId !== assetId));
        delete this.newInvestmentsForm[assetId];
        this.newInvestments.delete(assetId);
    }

    filterNotInvested(assets: Asset[], fundInvestments: FundInvestment[]): Asset[] {
        return assets.filter(asset => !fundInvestments.map(f => f.assetId).includes(asset.id ?? -1));
    }

    save() {
        if (!this.fundId || !this.fundReportId) {
            return;
        }

        const fundInvestmentsToSave = this.fundInvestments.map(investment => {
            const form = this.newInvestmentsForm[investment.assetId];
            if (form) {
                return {
                    ...investment,
                    investmentDate: {
                        ...investment.investmentDate,
                        date: DateUtil.toIsoDate(form.value.investmentDate)
                    },
                    ownershipStake: {
                        ...investment.ownershipStake,
                        fraction: DecimalFormatPipe.transformPercentToFraction(form.value.ownershipStake)
                    },
                    isRealized: {
                        ...investment.isRealized,
                        value: false
                    }
                };
            } else {
                return investment;
            }
        });

        this.store.dispatch(FundInvestmentActions.saveall({
            fundId: this.fundId,
            fundReportId: this.fundReportId,
            fundInvestments: fundInvestmentsToSave
        }));
    }

    getFormControl(assetId: number, controlName: string): FormControl {
        if (this.newInvestments.has(assetId)) {
            const form = this.newInvestmentsForm[assetId];
            return form ? form.get(controlName) as FormControl : new FormControl();
        } else {
            return new FormControl({value: "", disabled: true});
        }
    }

    areAllFormsValid(): boolean {
        return Object.values(this.newInvestmentsForm).every(form => form.valid);
    }
}
