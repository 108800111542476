import {Component, Input, OnInit} from "@angular/core";
import {AssetActions, AssetValuationActions} from "../../../../asset/store/asset/asset.actions";
import {FundInvestmentActions} from "../../../store/fund.actions";
import {Store} from "@ngrx/store";
import {selectAssetName, selectIsAnyAssetFormInEditMode} from "../../../../asset/store/asset/asset.selectors";
import {selectSelectedSourceDataset} from "../../../../import/store/import.selectors";
import {MatDialogRef} from "@angular/material/dialog";
import {map} from "rxjs";
import {Router} from "@angular/router";

export enum assetDialogExpandType {
    GENERAL = "general",
    GPVALUATION = "gpvaluation",
    EXITINFO = "exitinfo",
    DMV = "dmv"
}

@Component({
    selector: "valumize-asset-dmv-valuation-dialog",
    templateUrl: "./asset-details-dialog.component.html",
    styleUrls: ["./asset-details-dialog.component.scss"]
})
export class AssetDetailsDialogComponent implements OnInit {

    @Input() assetId?: number;
    @Input() fundId?: number;
    @Input() fundReportId?: number;
    @Input() fundValuationId?: number;
    @Input() assetValuationId?: number;
    @Input() fundInvestmentId?: number;
    @Input() expanded: assetDialogExpandType = assetDialogExpandType.DMV;

    protected readonly assetDialogExpandType = assetDialogExpandType;

    assetName$ = this.store.select(selectAssetName);
    sourceDataset$ = this.store.select(selectSelectedSourceDataset);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);

    isEditDisabled = false;
    isCloseDisabled = false;
    isAssetDetailsDisabledInfo = false;

    constructor(public dialogRef: MatDialogRef<AssetDetailsDialogComponent>,
                private readonly store: Store,
                private readonly router: Router) {
        this.dialogRef.disableClose = true; // Disable default close behavior
    }

    ngOnInit(): void {
        this.store.dispatch(AssetActions.clearassetstate());
        if (this.assetId) {
            this.store.dispatch(AssetActions.load({assetId: this.assetId}));
            if (this.fundId) {
                if (this.fundReportId) {
                    if (this.fundInvestmentId) {
                        this.store.dispatch(FundInvestmentActions.load({fundId: this.fundId, fundReportId: this.fundReportId, fundInvestmentId: this.fundInvestmentId}));

                        if (this.assetValuationId) {
                            this.store.dispatch(AssetValuationActions.load({assetId: this.assetId, assetValuationId: this.assetValuationId}));
                        }
                    }
                }
            }
        }
        this.isEditDisabled$.pipe(
            map((isDisabled) => {
                this.dialogRef.disableClose = isDisabled;
                this.isEditDisabled = isDisabled;
                if (!isDisabled) {
                    this.isCloseDisabled = false;
                    this.isAssetDetailsDisabledInfo = false;
                }
            })).subscribe();
    }

    closeDialog(): void {
        if (!this.isEditDisabled) {
            this.dialogRef.close();
        } else {
            this.isCloseDisabled = true;
        }
    }

    openAssetDetails(): void {
        if (!this.isEditDisabled) {
            this.dialogRef.close();
            this.router.navigate(["../../assets/", this.assetId], {
                queryParams: {
                    fundId: this.fundId,
                    fundReportId: this.fundReportId,
                    fundValuationId: this.fundValuationId,
                    fundInvestmentId: this.fundInvestmentId,
                    assetValuationId: this.assetValuationId
                }
            });
        } else {
            this.isAssetDetailsDisabledInfo = true;
        }
    }
}
