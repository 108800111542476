import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ImportState} from "./import.state";
import {importStoreFeatureKey} from "./import.reducer";

export const selectImportState = createFeatureSelector<ImportState>(importStoreFeatureKey);

export const selectFileNames = createSelector(
    selectImportState,
    (state) => state.fileNames
);

export const selectDatasets = createSelector(
    selectImportState,
    (state) => state.datasets
);

export const selectImportLogs = createSelector(
    selectImportState,
    (state) => state.importLogs.data
);

export const selectSelectedSourceDataset = createSelector(
    selectImportState,
    (state) => state.selectedSourceDataset
);

export const selectSelectedTargetDataset = createSelector(
    selectImportState,
    (state) => state.selectedTargetDataset
);

