/* eslint-disable arrow-body-style */
import {inject} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {catchError, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {NoteService} from "src/app/shared/services/note/note.service";
import {Asset} from "../../models/asset/asset";
import {AssetService} from "../../services/asset.service";
import {
    AssetActions,
    AssetDescriptionActions,
    AssetFinancialHistoryActions,
    AssetGeneralInformationActions,
    AssetListActions,
    AssetNameActions,
    AssetNoteActions,
    AssetSwotActions,
    EndMarketsCustomersActions,
    GeneralPartnerValuationActions,
    ShareholderActions
} from "./asset.actions";
import {selectAssetName, selectAssetState, selectHasBaselineAsset, selectSelectedAsset, selectSelectedAssetId, selectSelectedAssetNotes} from "./asset.selectors";
import {EMPTY_ASSET} from "../asset.reducer";
import {FundInvestmentService} from "../../../fund/services/fund-investment.service";
import {selectSelectedFundId} from "../../../fund/store/fund/fund.selectors";
import {selectSelectedFundReportId} from "../../../fund/store/fund-report/fund-report.selectors";
import {FundActions, FundInvestmentActions} from "../../../fund/store/fund.actions";
import {selectSelectedFundInvestment} from "../../../fund/store/fund-investment/fund-investment.selectors";
import {DealActions} from "../../../deal/store/deal.actions";
import {selectSelectedSourceDataset} from "../../../import/store/import.selectors";
import {tap} from "rxjs/operators";

export const initAssetOverview = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetListActions.open),
            switchMap(() => of(
                AssetActions.clearassetstate(),
                AssetListActions.load(),
                FundActions.clearfundstate(),
                DealActions.cleardealstate()
            ))
        );
    },
    {functional: true}
);

export const initAssetDetailsPage = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open),
            switchMap((action) =>
                !!action.fundId
                    ? of(FundActions.load({fundId: action.fundId}), AssetActions.load({assetId: action.assetId}))
                    : of(DealActions.cleardealstate(), FundActions.clearfundstate(), AssetActions.load({assetId: action.assetId})))
        );
    },
    {functional: true}
);

export const loadAssetList = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetListActions.load),
            withLatestFrom(store$.select(selectSelectedSourceDataset)),
            exhaustMap(([action, dataset]) =>
                assetService.getAssets(dataset).pipe(
                    map((assets) => AssetListActions.loaded({assets})),
                    catchError((error: { message: string }) =>
                        of(AssetActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const createAsset = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetActions.create),
            withLatestFrom(store$.select(selectSelectedSourceDataset)),
            exhaustMap(([action, dataset]) => {
                return assetService.saveAsset({
                    ...EMPTY_ASSET,
                    ...{
                        name: {
                            ...EMPTY_ASSET.name,
                            text: action.assetName
                        }
                    }
                }, undefined, dataset).pipe(
                    switchMap((asset) => of(
                        AssetActions.clearassetstate(),
                        FundActions.clearfundstate(),
                        DealActions.cleardealstate(),
                        AssetActions.loaded({asset})
                    )),
                    catchError((error: { message: string }) =>
                        of(AssetActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadAsset = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetActions.load),
            withLatestFrom(store$.select(selectHasBaselineAsset)),
            exhaustMap(([action, hasBaselineAsset]) =>
                assetService.getAsset(action.assetId).pipe(
                    switchMap((asset) => {
                        return (asset.mergeTargetId && !hasBaselineAsset) ?
                            of(
                                AssetActions.loaded({asset}),
                                AssetActions.loadbaseline({assetId: asset.mergeTargetId}),
                                AssetFinancialHistoryActions.loadbaseline({assetId: asset.mergeTargetId})
                            ) :
                            of(AssetActions.loaded({asset}));
                    }),
                    catchError((error: { message: string }) =>
                        of(AssetActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadBaselineAsset = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetActions.loadbaseline),
            exhaustMap((action) =>
                assetService.getAsset(action.assetId).pipe(
                    map((asset) => AssetActions.loadedbaseline({asset})),
                    catchError((error: { message: string }) =>
                        of(AssetActions.loaderror({errorMsg: "Baseline Asset" + error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const saveAssetName = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetNameActions.save),
            withLatestFrom(store$.select(selectAssetState)),
            exhaustMap(([action, state]) => {
                const selectedAsset: Asset = state.selectedAsset.data;
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            name: {
                                ...selectedAsset.name,
                                text: action.assetName
                            }
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.loaded({asset})),
                        catchError((error: { message: string }) =>
                            of(AssetActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Asset id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveAssetDescription = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetDescriptionActions.save),
            withLatestFrom(store$.select(selectAssetState)),
            exhaustMap(([action, state]) => {
                const selectedAsset: Asset = state.selectedAsset.data;
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            description: {
                                ...selectedAsset.description,
                                text: action.assetDescription
                            },
                            url: {
                                ...selectedAsset.url,
                                text: action.assetUrl
                            }
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.loaded({asset})),
                        catchError((error: { message: string }) =>
                            of(AssetActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Asset id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const generateAssetDescription = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetDescriptionActions.gptedit),
            withLatestFrom(store$.select(selectAssetName)),
            exhaustMap(([, selectedAssetName]) => {
                const assetName = selectedAssetName.name.text;
                if (assetName) {
                    return assetService.getGptCompanyDescription(assetName).pipe(
                        map((response) => AssetDescriptionActions.generatesuccess({description: response})),
                        catchError((error: { message: string }) =>
                            of(AssetDescriptionActions.generatefailure({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetDescriptionActions.generatefailure({errorMsg: "Asset Name is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadAssetNotes = createEffect(
    (actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(AssetActions.open, AssetNoteActions.load),
            exhaustMap((action) => {
                const inContext = "inContext" in action
                    ? action.inContext
                    : `/assets/asset_id=${action.assetId}/`;
                return noteService.getNotes(inContext).pipe(
                    map((notes) => AssetNoteActions.loaded({notes})),
                    catchError((error: { message: string }) =>
                        of(AssetNoteActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveAssetNote = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(AssetNoteActions.save),
            withLatestFrom(store$.select(selectSelectedAssetNotes)),
            exhaustMap(([action, state]) => {
                return noteService.save(action.note, action.context).pipe(
                    map(() => AssetNoteActions.load({inContext: state.baseContext})),
                    catchError((error: { message: string }) =>
                        of(AssetNoteActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const deleteAssetNote = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(AssetNoteActions.delete),
            withLatestFrom(store$.select(selectSelectedAssetNotes)),
            exhaustMap(([action, state]) => {
                if (action.note.id === undefined) {
                    return of();
                }
                return noteService.delete(action.note.id).pipe(
                    map(() => AssetNoteActions.load({inContext: state.baseContext})),
                    catchError((error: { message: string }) =>
                        of(AssetNoteActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveAssetGeneralInformation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetGeneralInformationActions.save),
            withLatestFrom(store$.select(selectAssetState)),
            exhaustMap(([action, state]) => {
                const selectedAsset: Asset = state.selectedAsset.data;
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            country: {
                                ...selectedAsset.country,
                                code: action.country
                            },
                            activityDescription: {
                                ...selectedAsset.activityDescription,
                                text: action.activityDescription ?? undefined
                            },
                            regions: action.regions,
                            stage: {
                                ...selectedAsset.stage,
                                code: action.stage
                            },
                            gic: {
                                ...selectedAsset.gic,
                                text: action.gic ?? undefined
                            },
                            gpIndication: {
                                ...selectedAsset.gpIndication,
                                code: action.gpIndication
                            },
                            currencyIso: {
                                ...selectedAsset.currencyIso,
                                code: action.currency
                            },
                            esg: {
                                ...selectedAsset.esg,
                                code: action.esg
                            },
                            fiscalYearEnd: {
                                ...selectedAsset.fiscalYearEnd,
                                code: action.fiscalYearEnd
                            },
                            assessment: {
                                ...selectedAsset.assessment,
                                code: action.assessment
                            },
                            publiclyListed: action.publiclyListed
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.loaded({asset})),
                        catchError((error: { message: string }) =>
                            of(AssetActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Asset id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadGeneralPartnerValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(GeneralPartnerValuationActions.load),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundReportId)
            ),
            exhaustMap(([action, fundId, fundReportId]) => {
                if (!!fundId && !!fundReportId && !!action.fundInvestmentId) {
                    return fundInvestmentService.getFundInvestment(fundId, fundReportId, action.fundInvestmentId).pipe(
                        map((fundInvestment) => GeneralPartnerValuationActions.loaded({fundInvestment})),
                        catchError((error: { message: string }) =>
                            of(GeneralPartnerValuationActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(GeneralPartnerValuationActions.loaderror({errorMsg: "FundId, FundReportId & FundInvestmentId are required"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveGeneralPartnerValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(GeneralPartnerValuationActions.save),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundReportId)
            ),
            exhaustMap(([action, fundId, fundReportId]) => {
                if (!!fundId && !!fundReportId && !!action.fundInvestment.id) {
                    return fundInvestmentService.updateFundInvestment(fundId, fundReportId, action.fundInvestment.id, action.fundInvestment)
                        .pipe(
                            switchMap((fundInvestmentResponse) => of(
                                    GeneralPartnerValuationActions.savedloaded({fundInvestment: fundInvestmentResponse}),
                                    !!fundInvestmentResponse.id
                                        ? FundInvestmentActions.load({fundId, fundReportId, fundInvestmentId: fundInvestmentResponse.id})
                                        : FundInvestmentActions.loaderror({errorMsg: "Fund Investment could not be loaded"})
                                )
                            ),
                            catchError((error: { message: string }) =>
                                of(GeneralPartnerValuationActions.loaderror({errorMsg: error.message}))
                            )
                        );
                } else {
                    return of(GeneralPartnerValuationActions.loaderror({errorMsg: "FundId, FundReportId & FundInvestmentId are required"}));
                }
            })
        );
    },
    {functional: true}
);

export const calcGeneralPartnerValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(GeneralPartnerValuationActions.calc),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundReportId),
                store$.select(selectSelectedFundInvestment)
            ),
            exhaustMap(([action, fundId, fundReportId, fundInvestment]) => {
                if (fundId && fundReportId && fundInvestment.data.id) {
                    return fundInvestmentService.calcFundInvestment(fundId, fundReportId, fundInvestment.data.id, {
                        ...fundInvestment.data,
                        remainingCost: {
                            ...fundInvestment.data.remainingCost,
                            amount: action.remainingCost
                        },
                        mezzanine: {
                            ...fundInvestment.data.mezzanine,
                            amount: action.mezzanine
                        },
                        preferredEquity: {
                            ...fundInvestment.data.preferredEquity,
                            amount: action.preferredEquity
                        },
                        commonEquity: {
                            ...fundInvestment.data.commonEquity,
                            amount: action.commonEquity
                        },
                        realizedCost: {
                            ...fundInvestment.data.realizedCost,
                            amount: action.realizedCost
                        },
                        realizedGains: {
                            ...fundInvestment.data.realizedGains,
                            amount: action.realizedGains
                        },
                    }).pipe(
                        map((fundInvestmentResponse) => GeneralPartnerValuationActions.calcsuccess({fundInvestment: fundInvestmentResponse})),
                        catchError((error: { message: string }) =>
                            of(GeneralPartnerValuationActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(GeneralPartnerValuationActions.loaderror({errorMsg: "FundId, FundReportId & FundInvestmentId are required"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveSwot = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetSwotActions.save),
            withLatestFrom(store$.select(selectAssetState)),
            exhaustMap(([action, state]) => {
                const selectedAsset: Asset = state.selectedAsset.data;
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            swot: {
                                ...selectedAsset.swot,
                                ...{
                                    swotStrengths: {
                                        ...selectedAsset.swot.swotStrengths,
                                        text: action.swotStrengths
                                    },
                                    swotWeaknesses: {
                                        ...selectedAsset.swot.swotWeaknesses,
                                        text: action.swotWeaknesses
                                    },
                                    swotOpportunities: {
                                        ...selectedAsset.swot.swotOpportunities,
                                        text: action.swotOpportunities
                                    },
                                    swotThreats: {
                                        ...selectedAsset.swot.swotThreats,
                                        text: action.swotThreats
                                    }
                                }
                            }
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.savedloaded({asset})),
                        catchError((error: { message: string }) =>
                            of(AssetActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Asset id is required"}));
                }

            })
        );
    },
    {functional: true}
);

export const generateSwotAnalysis = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetSwotActions.gptedit),
            withLatestFrom(store$.select(selectAssetName)),
            exhaustMap(([, selectedAssetName]) => {
                const assetName = selectedAssetName.name.text;
                if (assetName) {
                    return assetService.getGptSwotAnalysis(assetName).pipe(
                        map((response) => AssetSwotActions.generatesuccess({swot: response})),
                        catchError((error: { message: string }) =>
                            of(AssetSwotActions.generatefailure({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetSwotActions.generatefailure({errorMsg: "Asset Name is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveEndMarketsCustomers = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(EndMarketsCustomersActions.save),
            withLatestFrom(store$.select(selectAssetState)),
            exhaustMap(([action, state]) => {
                const selectedAsset: Asset = state.selectedAsset.data;

                // Check if asset has ID
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            endMarketsCustomers: {
                                ...selectedAsset.endMarketsCustomers,
                                ...{
                                    text: action.text
                                }
                            }
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.savedloaded({asset})),
                        catchError((error: { message: string }) =>
                            of(AssetActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Asset id is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const generateEndMarketsDescription = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(EndMarketsCustomersActions.gptedit),
            withLatestFrom(store$.select(selectAssetName)),
            exhaustMap(([, selectedAssetName]) => {
                const assetName = selectedAssetName.name.text;
                if (assetName) {
                    return assetService.getGptEndMarkets(assetName).pipe(
                        map((response) => EndMarketsCustomersActions.generatesuccess({description: response})),
                        catchError((error: { message: string }) =>
                            of(EndMarketsCustomersActions.generatefailure({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(EndMarketsCustomersActions.generatefailure({errorMsg: "Asset Name is required"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadAssetFinancialHistory = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetFinancialHistoryActions.load),

            exhaustMap((action) =>
                assetService.getAssetFinancialHistory(action.assetId).pipe(
                    map((financialHistory) =>
                        AssetFinancialHistoryActions.loadsuccess({financialHistory})
                    ),
                    catchError((error: { message: string }) =>
                        of(AssetFinancialHistoryActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadBaselineAssetFinancialHistory = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetFinancialHistoryActions.loadbaseline),
            exhaustMap((action) =>
                assetService.getAssetFinancialHistory(action.assetId).pipe(
                    map((financialHistory) => AssetFinancialHistoryActions.loadedbaseline({financialHistory})),
                    catchError((error: { message: string }) =>
                        of(AssetFinancialHistoryActions.loaderror({errorMsg: "Baseline Asset Financial History" + error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const saveAssetFinancialHistory = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetFinancialHistoryActions.save),
            withLatestFrom(store$.select(selectSelectedAssetId)),

            exhaustMap(([action, assetId]) => {
                if (assetId === undefined) {
                    return of();
                }
                return assetService.saveAssetFinancialHistory(assetId, action.financialHistory).pipe(
                    map((financialHistory) => AssetFinancialHistoryActions.savedloaded({financialHistory})),
                    catchError((error: { message: string }) =>
                        of(AssetFinancialHistoryActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const calcAssetFinancialHistory = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetFinancialHistoryActions.calc),
            withLatestFrom(store$.select(selectSelectedAssetId)),

            exhaustMap(([action, assetId]) => {
                if (assetId === undefined) {
                    return of();
                }
                // Assuming action has the necessary data for calculation
                return assetService.calcAssetFinancialHistory(assetId, action.financialHistoryRecord).pipe(
                    map((calculatedFinancialHistory) => AssetFinancialHistoryActions.calcsuccess({financialHistory: calculatedFinancialHistory})),
                    catchError((error: { message: string }) =>
                        of(AssetFinancialHistoryActions.calcerror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadAllShareholders = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(ShareholderActions.load),
            exhaustMap((action) => {
                return assetService.getAllShareholders(action.assetId).pipe(
                    switchMap((shareholders) => of(ShareholderActions.loadallsuccess({shareholders}))),
                    catchError((error: { message: string }) => of(ShareholderActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const loadShareholdersForDate = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(ShareholderActions.load),
            switchMap((action) => {
                return assetService.getShareholdersForDate(action.assetId, action.date).pipe(
                    map((shareholders) => ShareholderActions.loadsuccess({shareholders})),
                    catchError((error: { message: string }) => of(ShareholderActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);


export const saveShareholdersForDate = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(ShareholderActions.save),
            exhaustMap((action) => {
                if (action.assetId === undefined || !action.date) {
                    return of(ShareholderActions.saveerror({errorMsg: "Asset ID and/or Date are missing"}));
                }
                return assetService.saveShareholdersForDate(action.assetId, action.date, action.shareholders).pipe(
                    switchMap((shareholders) => of(ShareholderActions.savedloaded({shareholders}))),
                    catchError((error: { message: string }) => of(ShareholderActions.saveerror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const setMergeTargetId = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetActions.setmergetargetid),
            withLatestFrom(store$.select(selectSelectedAsset)),
            exhaustMap(([action, selectedAssetData]) => {
                const selectedAsset: Asset = selectedAssetData.data;
                const assetId = selectedAsset.id;
                if (assetId) {
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            mergeTargetId: action.mergeTargetId
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.savedloaded({asset})),
                        tap(() => window.location.reload()),
                        catchError((error: { message: string }) =>
                            of(AssetActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Asset id is required"}));
                }

            })
        );
    },
    {functional: true}
);

